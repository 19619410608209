import React, { createContext, useContext } from  'react';
import API from '../services/api.service';
import EventbusService from '../services/eventbus.service';
import EVENTS from '../events';
import { ACCOUNT_TYPES } from './banking.service.codes';


const bankingContext = createContext();
let bankingService = null;

export const BankingContext = function({ children }) {
    if (!bankingService) {
        bankingService = new BankingService();
    }
    return (
        <bankingContext.Provider value={bankingService}>
            {children}
        </bankingContext.Provider>
    );
};

export const useBankingService = function() {
    return useContext(bankingContext);
};

function BankingService() {

    let busy = true;
    let accounts = [];
    let accountTransactions = [];

    function setAccounts(updatedAccounts) {
        accounts = updatedAccounts;
        return accounts;
    }

    function getAccountTypes() {
        return ACCOUNT_TYPES;
    }

    function getAccounts() {
        return accounts;
    }

    function getParentAccounts() {
        return getAccounts().filter(function(account) {
            return !account.parentAccountId;
        });
    }

    function getChildAccounts(parentAccountId) {
        return getAccounts().filter(function(account) {
            return account.parentAccountId === parentAccountId;
        });
    }

    function hasChildAccounts(accountId) {
        return getChildAccounts(accountId).filter(function(account) {
            return !account.deletedOn;
        }).length > 0;
    }

    function postAccount(account) {
        if (!account || !account.accountId) {
            return Promise.reject({message: 'invalid data'});
        }
        return API.postBankAccount(account).then(function(result) {

            return result;
        });
    }

    function postCloseAccount(accountId, payload) {
        return API.postBankingAccountClose(accountId, payload);
    }

    function getAccount(accountId) {
        return accounts.find(function(account) {
            return account.accountId === accountId;
        });
    }

    function getAccountTransactions() {
        return accountTransactions;
    }

    function setAccountTransactions(transactions) {
        accountTransactions = transactions;
        return accountTransactions;
    }

    function loadAccountTransactions(accountId) {
        return API.getBankAccountTransactions(accountId).then(setAccountTransactions);
    }

    function postBankAccountTransaction(transaction) {
        return API.postBankAccountTransaction(transaction);
    }

    function postBankAccountTransactions(transactions) {
        return API.postBankAccountTransactions(transactions);
    }

    function refreshTask() {
        console.log('BankingService - refreshTask');
        const tasks = [API.getBankAccounts().then(setAccounts)];

        Promise.all(tasks).then(function() {
            EventbusService.emit(EVENTS.LOCAL.MYBANK.ACCOUNT.UPDATED, accounts);
        });
    }

    function init() {
        console.log('BankingService - init');
        refreshTask();
    }

    console.log('BankingService - refreshTask - init');
    EventbusService.on(EVENTS.BROADCAST_EVENTS.MYBANK.ACCOUNT.UPDATED, refreshTask);
    EventbusService.on(EVENTS.LOGGED_IN, refreshTask);
    init();

    return {
        busy,
        getAccount,
        getAccounts,
        postCloseAccount,
        getParentAccounts,
        getChildAccounts,
        hasChildAccounts,
        postAccount,
        getAccountTypes,
        loadAccountTransactions,
        getAccountTransactions,
        postBankAccountTransaction,
        postBankAccountTransactions
    };
};

export default BankingService;