export const FREQUENCIES = [
    { name: 'Weekly', code:'WEEK', perYear: 52 },
    { name: 'Fortnightly', code:'FORTNIGHT', perYear: 26 },
    { name: '4 Weekly', code:'4WEEK', perYear: 13 },
    { name: 'Monthly', code:'MONTH', perYear: 12 },
    { name: 'Yearly', code:'YEAR',perYear: 1 }
];

export const ACCOUNT_TYPES =  [
    { code:'SAVINGS',   name: 'Savings', order: 2 },
    { code:'CHEQUE',    name: 'Cheque' , order: 1 },
    { code:'CREDIT',    name: 'Credit Card', order: 3 },
    { code:'KIWISAVER', name: 'Kiwisaver', order: 4 },
    { code:'MORTGAGE',   name: 'Mortgage', order: 5 }
];