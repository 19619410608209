import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useBankingService } from './banking.service';

import 'react-datepicker/dist/react-datepicker.css';

function BankAppAccountEditTransaction(props) {

    const isSaving = props.isSaving;
    const isSaved = props.isSaved;
    const isCancelling = props.isCancelling;
    const bankingService = useBankingService();
    const [transaction] = useState(props.transaction);
    const [transactionAmount, setTransactionAmount] = useState(props.transaction.amount);
    const [transactionDate, setTransactionDate] = useState(new Date(props.transaction.effectiveDate).valueOf());
    const [transactionDescription, setTransactionDescription] = useState(props.transaction.description || '');
    const [busy, setBusy] = useState(false);
    const [canDeleteTransaction, setCanDeleteTransaction] = useState(false);
    const [canUpdateTransaction, setCanUpdateTransaction] = useState(false);

    useEffect( () => {

        if ((Math.round(transactionAmount * 100)/100) !== (Math.round(transaction.amount * 100)/100)) {
            setCanUpdateTransaction(true);
        } else if (transactionDescription !== transaction.description) {
            setCanUpdateTransaction(true);
        } else if ((transactionDate && new Date(transactionDate).toString()) !== new Date(transaction.effectiveDate).toString()) {
            setCanUpdateTransaction(true);
        } else {
            setCanUpdateTransaction(false);
        }

        return () =>{

        };
    }, [transactionAmount, transactionDate, transactionDescription]);



    const handleSaveAccountOnClick = () => {

        transaction.effectiveDate = transactionDate;
        transaction.amount = transactionAmount;
        transaction.description = transactionDescription;

        isSaving(true);
        setBusy(true);
        return bankingService.postBankAccountTransaction(transaction)
            .then(function() {
                isSaved(true);
            }).catch(function() {
            }).finally(function() {
                setBusy(false);
            });

    };
    const handleCancelOnClick = () => {
        setBusy(true);
        isCancelling(true);
    };

    const handleDeleteOnClick = () => {
        setBusy(true);
        transaction.deletedOn = Date.now();
        handleSaveAccountOnClick();
    };

    const onDeleteConfirmChange = (value) => {
        setCanDeleteTransaction(value && value.trim().toLowerCase() === 'delete');
    };

    function onTransactionAmountChange(amount) {
        setTransactionAmount(amount.floatValue);
    }

    function onTransactionDateChange(effectiveDate) {
        if(effectiveDate && new Date(effectiveDate).valueOf() > 0) {
            const d = new Date(effectiveDate);
            d.setHours(0, 0, 0, 0);
            setTransactionDate(d.valueOf());
        } else {
            setTransactionDate(null);
        }
    }

    function onDesciptionChange(description) {
        if (description && description.target) {
            setTransactionDescription(description.target.value);
        }
    }


    return (
        <>
            <Form>
            <Form.Group className="mb-3" controlId="formAccountDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control type='text'  disabled={busy} placeholder='desciption' value={transactionDescription} onChange={onDesciptionChange} ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAccountAmount">
                <Form.Label>Amount</Form.Label>
                {<NumberFormat className='form-control' disabled={busy}
                    style={{color: (Math.round(transactionAmount * 100)/100) < 0 ? 'Red': 'inherit', alignItems:'flex-end' }}
                    value={(Math.round(transactionAmount * 100)/100).toFixed(2)}
                    displayType={'input'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                    onValueChange={onTransactionAmountChange}/>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAccountDate">
                     <Form.Label>Date</Form.Label>
                <DatePicker className='form-control'  disabled={busy}
                    dateFormat='dd/MM/yyyy'
                selected={transactionDate} onChange={(date) => onTransactionDateChange(date)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="save">
                <Button variant="success" disabled={busy || !canUpdateTransaction || canDeleteTransaction} onClick={handleSaveAccountOnClick}  >
                    Save
                </Button>
                <Button variant="secondary" className='float-end' disabled={busy} onClick={handleCancelOnClick}  >
                    Cancel
                </Button>
            </Form.Group>
            <Form.Group className="mb-3" controlId="delete">
            <Form.Label><i>Type "<strong>delete</strong>" to remove transaction</i></Form.Label>
            <div className="input-group mb-3">
                <input type="text" className="form-control" autoComplete='false' onChange={(e) => (onDeleteConfirmChange(e.target.value))} placeholder="delete" aria-label="delete" aria-describedby="deleteConfirm" />
                <Button id="deleteConfirm"
                    variant="danger"
                    className='input-group-text float-end'
                    disabled={busy || !canDeleteTransaction }
                    onClick={handleDeleteOnClick}>
                    Delete
                </Button>
            </div>
            </Form.Group>
        </Form>
        </>
    );
}

export default BankAppAccountEditTransaction;