import React, { useEffect, useState } from 'react';
import { Routes, Route , useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import PageHeader from '../common/page-header';
import { getHeaderImageByPath } from '../app.menu';
import API from '../services/api.service';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

function AdminApp() {

    const animatedComponents = makeAnimated();
    const image = getHeaderImageByPath('/admin');
    const [accounts, setAccounts] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const navigate = useNavigate();
    function navigateBackOne() {
        navigate('/');
    };


    useEffect(() => {
        API.getUserRoles().then(function(r) {
            setUserRoles(r);
            API.getAdminAccounts().then(function(a) {
                setAccounts(a);
            });
       });

    }, []);

    const targetHeight = 30;

    const customStyles = {
        control: function(provided, state) {
            return {
                ...provided,
                borderRadius: '5px',
                minHeight: 'initial',
                margin: 0,
                padding: 0,
                color: state.isFocused ? '#212529' : provided.color,
                backgroundColor: '#fff',
                borderColor:  state.isFocused ? '#86b7fe' : provided.borderColor,
                outline: state.isFocused ? '0' : null,
                boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : null
            };
        },
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
          }),
        option: function (provided) {
            return {
              ...provided,
                borderBottom: '0px dotted pink',
                color: 'black',
                minHeight: '30px',
                height: '30px',
                padding: '2px 15px'
            };
        },

        valueContainer: (provided, state) => ({
            ...provided,
            margin: 0,
                padding: 0,
        }),
        multiValue: function(provided, state) {
            return {
                ...provided,
                color: 'white',
                borderRadius: '5px',
                backgroundColor: 'Red',
            };
        },
        multiValueLabel: function(provided, state) {
            return {
                ...provided,
                color: 'white',
                backgroundColor: 'black',
                borderRadius: '5px 0 0 5px',
            };
        },

    };

    function handleChange(selectValues) {
        this.roles = selectValues.map(function(select) {
            return select.value;
        });
        API.postAdminAccount(this).then(function(res) {
            console.log(res);
        });
    }

    function getAccounts() {
        return accounts;
    }
    /*
        https://getbootstrap.com/docs/5.2/utilities/display/#hiding-elements
        https://react-select.com/home
    */
    const sp6 = { span: 6 };
    const sp2 = { span: 2 };
    const sp3 = { span: 3 };
    const sp1 = { span: 1 };
    const sp4 = { span: 4 };

    return (<React.Fragment>
        <Container fluid="md" >
            <Routes>
                <Route path='/' element={
                    <React.Fragment>
                        <PageHeader title="Admin"
                            icon={image.icon}
                            navigate={navigateBackOne}
                            subtitle="&nbsp;">
                        </PageHeader>
                        <Container className="pt-3" style={{marginTop:'95px'}}>
                            <Row key="-1" style={{fontWeight:'bold'}}>
                                <Col xs={sp6} md={sp2} >Username</Col>
                                <Col xs={sp1} md={sp1} >Status</Col>
                                <Col          md={sp2} className="d-none d-md-block">EMail</Col>
                                <Col          md={sp6} className="d-none d-md-block">Roles</Col>
                            </Row>
                            {getAccounts().map(function(account, index) {
                                const defaultValues = account.roles.reduce(function(assigned, role) {
                                    const assignedRole = userRoles.find((p) => p.value === role);
                                    if (assignedRole) {
                                        assigned.push(assignedRole);
                                    }
                                    return assigned;
                                }, []);
                                return <Row key={index} style={{padding: '10px'}}>
                                            <Col xs={sp6} md={sp2}>{account.username}</Col>
                                            <Col xs={sp1} >Active</Col>
                                            <Col md={sp2} className="d-none d-md-block">{account.emailAddress}</Col>
                                            <Col md={sp6}>
                                                <Select id="permissions"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    defaultValue={defaultValues}
                                                    options={userRoles}
                                                    autosize={true}
                                                    styles={customStyles}
                                                    onChange={handleChange.bind(account)}
                                                    isMulti
                                                    required/></Col>
                                        </Row>;
                            })}
                        </Container>
                    </React.Fragment>} >
                </Route>
        </Routes>
        </Container>

    </React.Fragment>);
}

export default AdminApp;