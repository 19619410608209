function AdminApiService({makeRequest, buildRequest}) {

    function postAdminAccount({roles, isActive, emailAddress, username}) {
        return buildRequest()
            .then(function(req) {
                return makeRequest(req.post('api/admin/account', { roles, isActive, emailAddress, username }));
            });
    }

    function getAdminAccounts() {
        return buildRequest()
            .then(function(req) {
                return makeRequest(req.get('/api/admin/accounts'));
            });
    }

    function getUserRoles() {
        return buildRequest()
            .then(function(req) {
                return makeRequest(req.get('/api/admin/roles'));
            });
    }

    return {
        getAdminAccounts,
        getUserRoles,
        postAdminAccount
    };
};

export default AdminApiService;