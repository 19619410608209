function BankingApiService({makeRequest, buildRequest}) {

    function getBankAccounts() {
        return buildRequest().then(function(req) {
            return makeRequest(req.get('api/mybank/accounts'));
        });
    }

    function getBankAccountTransactions(accountId) {
        return buildRequest().then(function(req) {
            return makeRequest(req.get(`api/mybank/transactions/${accountId}`));
        });
    }

    function postBankAccountTransaction(payload) {
        return buildRequest().then(function(req) {
            return makeRequest(req.post('api/mybank/transaction/edit', payload));
        });
    }

    function postBankAccountTransactions(payload) {
        return buildRequest().then(function(req) {
            return makeRequest(req.post('/api/mybank/transactions', payload));
        });
    }

    function postBankAccount(account) {
        return buildRequest().then(function(req) {
            return makeRequest(req.post('/api/mybank/account', account));
        });
    }

    function postBankingAccountClose(accountId, payload = {}) {
        return buildRequest().then(function(req) {
            return makeRequest(req.post(`api/mybank/account/${accountId}/close`, payload));
        });
    }

    return {
        getBankAccounts,
        postBankAccount,
        postBankingAccountClose,
        getBankAccountTransactions,
        postBankAccountTransaction,
        postBankAccountTransactions
    };

}


export default BankingApiService;