import AppBanner from './banner/banner';
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router';
import LoginPage from './login/page-login';
import Home from './home/home';
import BankApp from './banking/page-index';
import BankAppAccount from './banking/page-accounts';
import ShoppingApp from './shopping/page-index';
import AdminApp from './admin/page-index';
import { BankingContext } from './banking/banking.service';
import PageFourZeroFour from './common/page-four-zero-four';
import WebsocketService from './services/websocket.service';
import EVENTS from './events';
import EventbusService from './services/eventbus.service';
import { AuthService, ProvideAuth, PrivateRoute } from './services/auth.service';
import { ProvideAppVersion } from './services/reload.service';
import { ProvideConfig } from './services/config.service';
import { AppAlerts } from './common/alerts';
import Roles from './app.roles';
import './app.css';

function App() {

  const location = useLocation();

  function onLoggedIn() {
    WebsocketService.connect();
  }

  function onLoggedOut() {
    WebsocketService.disconnect();
  }

  function onMessage(message) {
    console.log('events.BROADCAST_LISTENER.MESSAGE', message);
    if (message.subject && message.data && message.subject.startsWith(EVENTS.BROADCAST_EVENTS.EVENT_NAMESPACE)) {
        console.log('emit', message.subject);
        EventbusService.emit(message.subject, message.data);
    }
  }

  useEffect(() => {
    console.log('route has been changed', location);
  }, [location.pathname]);

  useEffect(() => {
      if (AuthService.isAuthenticated() ) {
        onLoggedIn();
      }
      EventbusService.on(EVENTS.LOGGED_IN, onLoggedIn);
      EventbusService.on(EVENTS.LOGGED_OUT, onLoggedOut);
      EventbusService.on(EVENTS.BROADCAST_LISTENER.MESSAGE, onMessage);
      return () => {
            EventbusService.off(onLoggedIn);
            EventbusService.off(onLoggedOut);
            EventbusService.off(onMessage);
      };
  });

  return (
    <ProvideAppVersion>
      <ProvideAuth>
          <ProvideConfig>
              <div className="app noselect" >
                  <AppBanner></AppBanner>
                  <AppAlerts></AppAlerts>
                  <Routes>
                      <Route path='/' element={<PrivateRoute><Home/></PrivateRoute>} ></Route>
                      <Route path='banking/*' element={
                        <PrivateRoute groupRoles={Roles.GROUP_BANKING}>
                          <BankingContext>
                            <BankApp/>
                          </BankingContext>
                        </PrivateRoute>
                      } ></Route>
                      <Route path='shopping/*' element={<PrivateRoute roles={[Roles.GROUP_SHOPPING]}>
                          <ShoppingApp/>
                      </PrivateRoute>} ></Route>
                      <Route path='admin/*' element={<PrivateRoute roles={[Roles.ADMIN_USER]}><AdminApp/></PrivateRoute>} ></Route>
                      <Route path='login' element={<LoginPage/>} />
                      <Route path="*" element={<PageFourZeroFour />} />
                  </Routes>
              </div>
          </ProvideConfig>
      </ProvideAuth>
      </ProvideAppVersion>
  );
}

export default App;



