import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useBankingService } from './banking.service';
import EVENTS from '../events';
import EventbusService from '../services/eventbus.service';


function BankAppAccountClose(props) {

    const isSaving = props.isSaving;
    const isSaved = props.isSaved;
    const isCancelling = props.isCancelling;
    const accountId = props.accountId;
    const [busy, setBusy] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [transferTo, setTransferTo] = useState(false);
    const [transferToAccountId, setTransferToAccountId] = useState();
    const [confirmed, setConfirmed] = useState();
    const [canCloseAccount, setCanCloseAccount] = useState(false);

    const bankingService = useBankingService();
    const accountToClose = bankingService.getAccount(accountId);

    function sortAccounts(a, b) {
        if (a.order > b.order) {
            return 1;
        }
        if (a.order < b.order) {
            return -1;
        }
        return 0;
    }
    useEffect(() => {
        if (!transferTo && confirmed === 'confirm') {
            setCanCloseAccount(true);
        } else if (transferToAccountId && confirmed === 'confirm') {
            setCanCloseAccount(true);
        } else {
            setCanCloseAccount(false);
        }

        return () => {

        };
    }, [transferTo, transferToAccountId, confirmed]);



    function filterAccounts(account) {
        return account.accountId !== accountToClose.accountId && !account.deletedOn;
    }

    function sortChildAccounts(a, b) {
        if (a.accountName < b.accountName) {
            return -1;
        }
        if (a.accountName > b.accountName) {
            return 1;
        }
        return 0;
    }

    function loadAccounts() {
        const p = bankingService.getParentAccounts()
        .filter(filterAccounts)
        .sort(sortAccounts)
        .reduce(function(parentAccounts, parentAccount) {

            const pa = {
                value: parentAccount.accountId,
                label: parentAccount.accountName,
                accountBalance: parentAccount.accountBalance,
            };

            const children = [pa].concat(bankingService.getChildAccounts(parentAccount.accountId)
                    .filter(filterAccounts)
                    .sort(sortChildAccounts)
                    .map(function(childAccount) {
                        return {
                            value: childAccount.accountId,
                            label: childAccount.accountName,
                            accountBalance: childAccount.accountBalance,
                        };
                    }));

            parentAccounts.push({
                value: parentAccount.accountId,
                label: parentAccount.accountName,
                accountBalance: parentAccount.accountTotalBalance,
                options: children
            });
            return parentAccounts;
        }, []);
        setAccounts(p);
    }

    useEffect(() => {
        loadAccounts();
        EventbusService.on(EVENTS.LOCAL.MYBANK.ACCOUNT.UPDATED, loadAccounts);
        return () => {
            EventbusService.off(loadAccounts);
        };
    }, []);


    const customStyles = {
        control: function(provided, state) {
            return {
                ...provided,
                borderRadius: '5px',
                color: state.isFocused ? '#212529' : provided.color,
                backgroundColor: '#fff',
                borderColor:  state.isFocused ? '#86b7fe' : provided.borderColor,
                outline: state.isFocused ? '0' : null,
                boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : null
            };
        }
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const accountFormat = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={{color: (Math.round(data.accountBalance * 100)/100) < 0 ? 'Red': 'inherit' }}>
                {<NumberFormat value={(Math.round(data.accountBalance * 100)/100).toFixed(2)} displayType={'text'} thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'} />}</span>
        </div>
    );

    const handleAccountSelected = (e) => {
        setTransferToAccountId(e.value);
    };

    const handleCloseAccountOnClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const payload = { transferToAccountId: null };
        if (transferTo && transferToAccountId) {
            payload.transferToAccountId = transferToAccountId;
        }
        isSaving(true);
        setBusy(true);
        bankingService.postCloseAccount(accountId, payload).then(function() {
            isSaved(true);
        }).catch(function(error) {
            console.log(error);
            isSaving(false);
            setBusy(false);
        });

    };

    const handleCancelOnClick = () => {
        isCancelling();
    };

    const handleToggleTransfer = () => {
        setTransferTo(!transferTo);
    };

    const handleConfirmChange = (input) => {
        setConfirmed(input.target.value.trim().toLowerCase());
    };

    return (
         <Form>
            <Form.Group className="mb-3" controlId="formAccountType">
                <Form.Label>Closing Balance: {<NumberFormat
                    value={(Math.round(accountToClose.accountBalance * 100)/100).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'} />}</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTransferBalance">
                <Form.Check
                    type="checkbox"
                    id="formTransferBalance"
                    label="Transfer closing balance to another account."
                    checked={transferTo}
                    onChange={handleToggleTransfer}
                    disabled={accountToClose.accountBalance === 0 || busy}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAccountType">
                <Form.Label>Transfer Balance to Account</Form.Label>
                    <Select id="transferToAccount"
                            name="transferToAccount"
                            styles={customStyles}
                            options={accounts}
                            isDisabled={accountToClose.accountBalance === 0 || busy || !transferTo}
                            required={accountToClose.accountBalance !== 0}
                            formatOptionLabel={accountFormat}
                            formatGroupLabel={accountFormat}
                            onChange={handleAccountSelected}
                            />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAccountConfirm">
                <Form.Label>Type "<strong>confirm</strong>" to complete</Form.Label>
                <input type="text" autoComplete="off"  id="formAccountConfirm" className="form-control" onChange={handleConfirmChange}></input>
            </Form.Group>
            <Button variant="danger" disabled={busy || !canCloseAccount} onClick={handleCloseAccountOnClick}  >
                Close Account
            </Button>
            <Button variant="secondary" className='float-end' disabled={busy} onClick={handleCancelOnClick}  >
                Cancel
            </Button>
         </Form>
    );
}


export default BankAppAccountClose;