import EventbusService from './eventbus.service';
import { AuthService } from './auth.service';
import BROADCAST_LISTENER from './websocket.events';
const FIVE_SECONDS = 5000;
const events = {
    BROADCAST_LISTENER
};

function getWebsocketUrl(httpUrl) {
    const url = new URL(httpUrl);
    url.protocol = url.protocol.replace('https', 'wss').replace('http', 'ws');
    return url;
}

const WebsocketService = function() {

    let ws;
    let serverUrl;
    let authProvider = null;
    let shouldStayConnected = false;
    let isConnected = false;
    let isConnecting = false;

    function getToken() {
        if (!authProvider) {
            return Promise.resolve(null);
        }
        return Promise.resolve(authProvider.getRawToken());
    }

    function stayConnected() {
        if (!shouldStayConnected) {
            return Promise.resolve(false);
        }

        return getToken()
            .then(function(token) {
                if (!token) {
                    return Promise.resolve(false);
                }
                if (isConnected) {
                    return Promise.resolve(isConnected);
                }
                if (isConnecting) {
                    return Promise.resolve(isConnected);
                }
                isConnecting = true;

                return new Promise(function(resolve) {
                    ws = new WebSocket(serverUrl, ['token', token]);
                    ws.onopen = function(event) {
                        isConnected = true;
                        isConnecting = false;
                        console.log('WebsocketService - onopen');
                        EventbusService.emit(events.BROADCAST_LISTENER.CONNECTED, event);
                        resolve(isConnected);
                    };

                    ws.onmessage = function(event) {
                        const message = JSON.parse(event.data);
                        console.log('WebsocketService - onmessage', message);
                        EventbusService.emit(events.BROADCAST_LISTENER.MESSAGE, message);
                    };

                    ws.onerror = function() {
                        console.log('WebsocketService - onerror');
                    };
                    ws.onclose = function(event) {
                        isConnected = false;
                        isConnecting = false;
                        console.log('WebsocketService - onclose');
                        EventbusService.emit(events.BROADCAST_LISTENER.DISCONNECTED, event);
                        if (shouldStayConnected) {
                            setTimeout(stayConnected, FIVE_SECONDS + Math.random() * FIVE_SECONDS);
                        }
                    };
                });
            });
    }

    function connect() {
        if (!serverUrl) {
            return Response.reject(NOT_CONFIGURED);
        }
        shouldStayConnected = true;
        return stayConnected();
    }

    function disconnect() {
        shouldStayConnected = false;
        if (ws != null) {
            ws.close();
            ws = null;
            isConnected = false;
        }
    }

    function init(url, authProviderService) {
        if (!serverUrl) {
            serverUrl = getWebsocketUrl(url);
        }
        authProvider = authProviderService || AuthService;
        if (authProvider && authProvider.isAuthenticated()) {
            connect();
        }
        console.log('WebsocketService - Init', serverUrl, authProvider);
    }

    return {
        init,
        connect,
        disconnect
    };
}();

export default WebsocketService;