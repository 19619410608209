import { FaPiggyBank, FaShoppingBasket } from 'react-icons/fa';
import { MdFastfood, MdSecurity } from 'react-icons/md';
import { BsPaypal } from 'react-icons/bs';
import { BiAbacus } from 'react-icons/bi';
import bankingImage from './images/banking-main.jpg';
import bankingAccountImage from './images/banking-accounts.jpg';
import bankingTransactionImage from './images/banking-transactions.jpg';

import shoppingImage from './images/shopping-main.jpg';
import shoppingProductsImage from './images/shopping-products.jpg';
import shoppingListImage from './images/shopping-list.jpg';
import shoppingMealImage from './images/shopping-meals.jpg';
import mealsImage from './images/meals-main.jpg';
import secuirtyImage from './images/secuirty-main.jpg';
import Roles from './app.roles';
import { AuthService } from './services/auth.service';

const menuItemCollection = [{
        title: 'Banking',
        roles: [...Roles.GROUP_BANKING],
        image: bankingImage,
        path:'/banking',
        parentPath:'/',
        icon: <FaPiggyBank/>,
        isActive: true
    },
    { title: 'Accounts' ,isActive: true , roles: [...Roles.GROUP_BANKING], image: bankingAccountImage, path:'/banking/accounts', parentPath:'/banking', icon: <FaPiggyBank/>},
    { title: 'Transactions' , isActive: true, roles: [...Roles.GROUP_BANKING], image: bankingTransactionImage, path:'/banking/transactions', parentPath:'/banking', icon: <BiAbacus/>},
    { title: 'Manual Transaction', isActive: true, roles: [...Roles.GROUP_BANKING], image: bankingTransactionImage, path:'/banking/transactions/manual', parentPath:'/banking/transactions', icon: <BsPaypal/>},
    { title: 'Bulk Transactions', isActive: true, roles: [...Roles.GROUP_BANKING], image: bankingTransactionImage, path:'/banking/transactions/bulk', parentPath:'/banking/transactions', icon: <BsPaypal/>},
    {
        title: 'Shopping',
        roles: [...Roles.GROUP_SHOPPING],
        image: shoppingImage,
        path:'/shopping',
        parentPath:'/',
        isActive: true,
        icon: <FaShoppingBasket/>
    },
    {
        title: 'Shopping List',
        roles: [...Roles.GROUP_SHOPPING],
        image: shoppingListImage,
        path:'/shopping/list',
        parentPath:'/shopping',
        isActive: true,
        icon: <FaShoppingBasket/>
    },
    {
        title: 'Products',
        roles: [...Roles.GROUP_SHOPPING],
        image: shoppingProductsImage,
        path:'/shopping/products',
        parentPath:'/shopping',
        isActive: true,
        icon: <FaShoppingBasket/>
    },
    {
        title: 'Meals',
        roles: [...Roles.GROUP_SHOPPING],
        image: shoppingMealImage,
        path:'/shopping/meals',
        parentPath:'/shopping',
        isActive: true,
        icon: <FaShoppingBasket/>
    },
    {   title: 'Meals',
        image: mealsImage,
        isActive: false,
        path:'/meals',
        parentPath:'/',
        icon: <MdFastfood/>
    },
    {   title: 'Admin',
        roles: [Roles.ADMIN_USER],
        isActive: true,
        image: secuirtyImage,
        path:'/admin',
        parentPath:'/',
        icon: <MdSecurity/>
    },
];


function getMenuItems(path = '/') {

    return menuItemCollection.reduce(function(menuItems, menuItem) {

        if (menuItem && menuItem.roles && AuthService.isAuthenticated() && !AuthService.isInAnyRole(menuItem.roles)) {
            return menuItems;
        }

        if (!menuItem.isActive) {
            return menuItems;
        }

        if (menuItem.parentPath === path) {
            menuItems.push(menuItem);
            return menuItems;
        }

        return menuItems;
    }, []);
}


export const getHeaderImageByPath = (path) => {
    return menuItemCollection.find(function(menuItem){
        return menuItem.path === path;
    });
};


export const getHeaderImageByParentPath = (path) => {
    return menuItemCollection.find(function(menuItem){
        return menuItem.parentPath === path;
    });
};

export default getMenuItems;