import React, { useEffect, useState } from  'react';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import PageHeader from '../common/page-header';
import DatePicker from 'react-datepicker';

import { useBankingService } from './banking.service';
import { Row, Col, Form, Button } from 'react-bootstrap';

import EventbusService from '../services/eventbus.service';
import EVENTS from '../events';
import uuid from 'react-uuid';
import { getHeaderImageByPath } from '../app.menu';

function shortDate(effectiveDate) {
    if(effectiveDate && new Date(effectiveDate).valueOf() > 0) {
        const d = new Date(effectiveDate);
        d.setHours(0, 0, 0, 0);
        return d.valueOf();
    }
    return effectiveDate;
}

function BankAppTransactionsManual() {

    const navigate = useNavigate();
    const location = useLocation();
    const bankingService = useBankingService();
    const bankingImage = getHeaderImageByPath(location.pathname);
    const [busy, setBusy] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [transactionDate, setTransactionDate] = useState(shortDate(new Date().valueOf()));
    const [transactionDescription, setTransactionDescription] = useState('');
    const [transactionAmount , setTransactionAmount]= useState(null);
    const [fromAccountId, setFromAccountId] = useState(null);
    const [toAccountId, setToAccountId] = useState(null);
    const [transaction , setTransaction] = useState({fromAccountId, toAccountId, effectiveDate: transactionDate, amount: 0});
    const [canCreateTransaction, setCanCreateTransaction] = useState(false);

    useEffect( () => {

        if (transaction) {
            if (transactionAmount > 0 && transactionDate && (toAccountId || fromAccountId)) {
                setCanCreateTransaction(true);
            } else {
                setCanCreateTransaction(false);
            }
        } else {
            setCanCreateTransaction(false);
        }
        return () =>{

        };
    }, [transactionAmount, transactionDate, transactionDescription, toAccountId, fromAccountId]);

    function navigateBackOne() {
        navigate('/banking/transactions');
    };

    function filterAccounts(account) {
        return !account.deletedOn;
    }

    function sortAccounts(a, b) {
        if (a.order > b.order) {
            return 1;
        }
        if (a.order < b.order) {
            return -1;
        }
        return 0;
    }

    function sortChildAccounts(a, b) {
        if (a.accountName < b.accountName) {
            return -1;
        }
        if (a.accountName > b.accountName) {
            return 1;
        }
        return 0;
    }

    const customStyles = {
        option: function (provided) {
            return {
              ...provided,
                borderBottom: '0px dotted pink',
                color: 'black'
            };
        },
        control: function(provided, state) {
            return {
                ...provided,
                borderRadius: '5px',
                color: state.isFocused ? '#212529' : provided.color,
                backgroundColor: '#fff',
                borderColor:  state.isFocused ? '#86b7fe' : provided.borderColor,
                outline: state.isFocused ? '0' : null,
                boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : null
            };
        }
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    function loadAccounts() {
        const p = bankingService.getParentAccounts()
        .filter(filterAccounts)
        .sort(sortAccounts)
        .reduce(function(parentAccounts, parentAccount) {

            const pa = {
                value: parentAccount.accountId,
                label: parentAccount.accountName,
                accountBalance: parentAccount.accountBalance,
            };

            const children = [pa].concat(bankingService.getChildAccounts(parentAccount.accountId)
                    .filter(filterAccounts)
                    .sort(sortChildAccounts)
                    .map(function(childAccount) {
                        return {
                            value: childAccount.accountId,
                            label: childAccount.accountName,
                            accountBalance: childAccount.accountBalance,
                        };
                    }));

            parentAccounts.push({
                value: parentAccount.accountId,
                label: parentAccount.accountName,
                accountBalance: parentAccount.accountTotalBalance,
                options: children
            });
            return parentAccounts;
        }, []);
        setAccounts(p);
        if (transaction.fromAccountId) {
            setFromAccountId(transaction.fromAccountId);
        }
    }

    useEffect(() => {
        loadAccounts();
        EventbusService.on(EVENTS.LOCAL.MYBANK.ACCOUNT.UPDATED, loadAccounts);
        return () => {
            EventbusService.off(loadAccounts);
        };
    }, []);

    const accountFormat = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={{color: (Math.round(data.accountBalance * 100)/100) < 0 ? 'Red': 'inherit' }}>
                {<NumberFormat value={(Math.round(data.accountBalance * 100)/100).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'} />}</span>
        </div>
    );

    function onTransactionDateChange(effectiveDate) {
        if(effectiveDate && new Date(effectiveDate).valueOf() > 0) {
            const d = new Date(effectiveDate);
            d.setHours(0, 0, 0, 0);
            setTransactionDate(d.valueOf());
            setTransaction( { ...transaction, effectiveDate: d.valueOf() });
        } else {
            setTransactionDate(null);
            setTransaction( { ...transaction, effectiveDate: null });
        }
    }

    const handleTransferFromAccountSelected = (accountId) => {
        const account = bankingService.getAccount(accountId);
        setFromAccountId(accountId);
        setTransaction( { ...transaction, fromAccountId : accountId, fromParentAccountId: account && account.parentAccountId });
    };

    const handleTransferToAccountSelected = (accountId) => {
        const account = bankingService.getAccount(accountId);
        setToAccountId(accountId);
        setTransaction( { ...transaction, toAccountId : accountId, toParentAccountId: account && account.parentAccountId  });
    };

    function onTransactionAmountChange(amount) {
        setTransactionAmount(amount.floatValue);
        setTransaction({ ...transaction, amount: amount.floatValue });
    }

    function onDesciptionChange(description) {
        setTransactionDescription(description);
        setTransaction({ ...transaction, description });
    }

    function handleSaveAccountOnClick() {
        const transactions = [];
        const transferId = uuid();
        if (transaction.fromAccountId) {
            const fromTx = {
                transactionId: uuid(),
                description: transaction.description,
                accountId: transaction.fromAccountId,
                parentAccountId: transaction.fromParentAccountId,
                effectiveDate: transaction.effectiveDate,
                amount: 0 - transaction.amount
            };

            if (transaction.toAccountId) {
                fromTx.transferId = transferId;
                fromTx.fromAccountId = transaction.toAccountId;
                fromTx.fromParentAccountId = transaction.toParentAccountId;
            }
            transactions.push(fromTx);
        }

        if (transaction.toAccountId) {
            const toTx = {
                transactionId: uuid(),
                description: transaction.description,
                accountId: transaction.toAccountId,
                parentAccountId: transaction.toParentAccountId,
                effectiveDate: transaction.effectiveDate,
                amount: transaction.amount
            };


            if (transaction.toAccountId) {
                toTx.transferId = transferId;
                toTx.fromAccountId = transaction.fromAccountId;
                toTx.fromParentAccountId = transaction.fromParentAccountId;
            }
            transactions.push(toTx);
        }
        setBusy(true);
        return bankingService.postBankAccountTransactions(transactions)
            .then(function() {
                setTransactionAmount(0);
                setTransactionDescription('');
            }).catch(function() {

            }).finally(function() {
                setBusy(false);
            });
    }

    return (
        <React.Fragment>
          <PageHeader title="Banking"
                icon={bankingImage.icon}
                subtitle="Manual Transaction"
                navigate={navigateBackOne}
                background={bankingImage.image}
                backgroundPosition='0 65%'
                 ></PageHeader>
                 <Form>
                 <Row style={{marginTop:'110px'}}>
                    <Col xs={{span: 12 }} sm={{span: 12 }} md={{span: 12 }} lg={{span: 6 }} xl={{span: 6 }}>
                        <Form.Group variant="dark" className="mb-1" controlId="transferFromAccount">
                            <Form.Label>From Account: </Form.Label>
                            <Select id="transferFromAccount"
                                name="transferFromAccount"
                                styles={customStyles}
                                options={accounts}
                                isDisabled={busy}
                                escapeClearsValue={true}
                                isClearable={true}
                                formatOptionLabel={accountFormat}
                                formatGroupLabel={accountFormat}
                                cache={false}
                                onChange={ (e) => { handleTransferFromAccountSelected(e && e.value); }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={{span: 12 }} sm={{span: 12 }} md={{span: 12 }} lg={{span: 6 }} xl={{span: 6 }}>
                        <Form.Group className="mb-1" controlId="transferToAccount">
                            <Form.Label>To Account: </Form.Label>
                            <Select id="transferToAccount"
                                styles={customStyles}
                                options={accounts}
                                isDisabled={busy}
                                escapeClearsValue={true}
                                isClearable={true}
                                formatOptionLabel={accountFormat}
                                formatGroupLabel={accountFormat}
                                onChange={(e) => { handleTransferToAccountSelected(e && e.value); }}/>
                        </Form.Group>
                    </Col>

                    </Row>
                    <Row>
                        <Col xs={{span: 12 }} sm={{span: 12 }} md={{span: 12 }} lg={{span: 6 }} xl={{span: 6 }}>
                            <Form.Group className="mb-1" controlId="formAccountDate">
                                <Form.Label>Date:</Form.Label>
                                <DatePicker className='form-control'
                                    disabled={busy}
                                    dateFormat='dd/MM/yyyy'
                                    maxDate={new Date()}
                                    selected={transactionDate}
                                    onChange={(date) => onTransactionDateChange(date)} />
                            </Form.Group>
                        </Col>

                        <Col xs={{span: 12 }} sm={{span: 12 }} md={{span: 12 }} lg={{span: 6 }} xl={{span: 6 }}>
                            <Form.Group className="mb-1" controlId="formAccountAmount">
                                <Form.Label>Amount:</Form.Label>
                                    {<NumberFormat className='form-control' disabled={busy}
                                        style={{color: (Math.round(transactionAmount * 100)/100) < 0 ? 'Red': 'black', alignItems:'flex-end' }}
                                        value={(Math.round(transactionAmount * 100)/100).toFixed(2)}
                                        displayType={'input'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        onFocus={event => event.target.select()}
                                        prefix={'$'}
                                        onValueChange={onTransactionAmountChange}/>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span: 12 }} sm={{span: 12 }} md={{span: 12 }} lg={{span: 6 }} xl={{span: 6 }}>
                            <Form.Group className="mb-1" controlId="formAccountDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type='text' autoComplete='off'  disabled={busy} placeholder='desciption' value={transactionDescription} onChange={(e) => onDesciptionChange(e && e.target && e.target.value)} ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span: 12 }} sm={{span: 12 }} md={{span: 12 }} lg={{span: 6 }} xl={{span: 6 }}>
                            <Button variant="success" disabled={busy || !canCreateTransaction} onClick={handleSaveAccountOnClick}  >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
        </React.Fragment>
    );
}

export default BankAppTransactionsManual;