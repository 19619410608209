import { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import API from './../services/api.service';

import Creatable from 'react-select/creatable';

function ShoppingProductCreateEdit(props) {

    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [sizes, setSizes] = useState([]);
    const [selectedSize, setSelectedSize] = useState(null);

    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);

    const isSaving = props.isSaving;
    const isSaved = props.isSaved;
    const [busy, setBusy] = useState(false);
    const [validated, setValidated] = useState(false);
    const isCreateMode = props.mode === 'add';
    const buttonText = isCreateMode ? 'Create': 'Update';
    const valueRule = /\W/g;

    function onCreateOption(fn, value) {
        eval(fn)({ value: value.replace(valueRule, '').toLowerCase(), label: value });
    }

    function mapToValueLabel(name, object) {
        return object.map(o => {
            return {
                value: o[`${name}Code`],
                label: o[`${name}Name`],
            };
        });
    }

    function onChange(fn, value) {
        eval(fn)(value);
    }

    function isValidNewOption(collection, input) {

        const values = eval(collection);
        const testValue = input.replace(valueRule, '').toLowerCase();
        if (testValue !== '' && values.filter(f => f.value.includes(testValue.toLowerCase())).length === 0) {
            return true;
        }
        return false;
    }

    async function onSubmit (event) {

        event.preventDefault();
        event.stopPropagation();

        const payload = {};
        if (selectedBrand) {
            payload.brand = {
                brandCode: selectedBrand.value,
                brandName: selectedBrand.label
            };
        }

        if (selectedProduct) {
            payload.product = {
                productCode: selectedProduct.value,
                productName: selectedProduct.label
            };
        }

        if (selectedSize) {
            payload.size = {
                sizeCode: selectedSize.value,
                sizeName: selectedSize.label
            };
        }

        if (selectedType) {
            payload.type = {
                typeCode: selectedType.value,
                typeName: selectedType.label
            };
        }

        await API.postShoppingProduct(payload).then(function(e) {
            console.log('postShoppingProduct', e);
        });
        console.log(payload);
    }

    useEffect(() => {
        API.getBrands().then(mapToValueLabel.bind(null, 'brand')).then(setBrands);
        API.getProducts().then(mapToValueLabel.bind(null, 'product')).then(setProducts);
        API.getSizes().then(mapToValueLabel.bind(null, 'size')).then(setSizes);
        API.getTypes().then(mapToValueLabel.bind(null, 'type')).then(setTypes);
  
    }, []);


    const customStyles = {
        control: function(provided, state) {
            return {
                ...provided,
                borderRadius: '5px',
                color: state.isFocused ? '#212529' : provided.color,
                backgroundColor: '#fff',
                borderColor:  state.isFocused ? '#86b7fe' : provided.borderColor,
                outline: state.isFocused ? '0' : null,
                boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : null
            };
        }
    };

    return (
        <>
                <Form autoComplete="off" noValidate validated={validated} onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="formBrand">
                        <Form.Label>Brand</Form.Label>
                        <Creatable id="brand"
                                name="brand"
                                isDisabled={busy}
                                onCreateOption={onCreateOption.bind(null, 'setSelectedBrand')}
                                isClearable
                                options={brands}
                                onChange={onChange.bind(null, 'setSelectedBrand')}
                                createOptionPosition={'first'}
                                formatCreateLabel={ value => `New Brand "${value}"`}
                                isValidNewOption={isValidNewOption.bind(null, 'brands')}
                                value={selectedBrand}
                                />
                        <Form.Control.Feedback type="invalid">
                            Select Brand!
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formProducts">
                        <Form.Label>Product</Form.Label>
                        <Creatable id="product"
                                name="product"
                                isDisabled={busy}
                                onCreateOption={onCreateOption.bind(null, 'setSelectedProduct')}
                                isClearable
                                options={products}
                                onChange={onChange.bind(null, 'setSelectedProduct')}
                                createOptionPosition={'first'}
                                formatCreateLabel={ value => `New Product "${value}"`}
                                isValidNewOption={isValidNewOption.bind(null, 'products')}
                                value={selectedProduct}
                                />
                        <Form.Control.Feedback type="invalid">
                            Select Product!
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSize">
                        <Form.Label>Size</Form.Label>
                        <Creatable id="size"
                                name="size"
                                isDisabled={busy}
                                onCreateOption={onCreateOption.bind(null, 'setSelectedSize')}
                                isClearable
                                options={sizes}
                                onChange={onChange.bind(null, 'setSelectedSize')}
                                createOptionPosition={'first'}
                                formatCreateLabel={ value => `New Size "${value}"`}
                                isValidNewOption={isValidNewOption.bind(null, 'sizes')}
                                value={selectedSize}
                                />
                        <Form.Control.Feedback type="invalid">
                            Select Size!
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formSize">
                        <Form.Label>Type</Form.Label>
                        <Creatable id="type"
                                name="type"
                                isDisabled={busy}
                                onCreateOption={onCreateOption.bind(null, 'setSelectedType')}
                                isClearable
                                options={types}
                                onChange={onChange.bind(null, 'setSelectedType')}
                                createOptionPosition={'first'}
                                formatCreateLabel={ value => `New Type "${value}"`}
                                isValidNewOption={isValidNewOption.bind(null, 'types')}
                                value={selectedType}
                                />
                        <Form.Control.Feedback type="invalid">
                            Select Size!
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={busy} >
                        {buttonText}
                    </Button>
                </Form>
        </>
    );
}

export default ShoppingProductCreateEdit;