const ADMIN_USER = 'ADMIN_USER';
const BANKING_USER = 'BANKING_USER';
const WEB_USER = 'WEB_USER';
const SHOPPING_ADMIN = 'SHOPPING_ADMIN';
const SHOPPING_USER = 'SHOPPING_ADMIN';

const Roles = {
    ADMIN_USER,
    WEB_USER,
    BANKING_USER,
    GROUP_BANKING: [ADMIN_USER, BANKING_USER],
    GROUP_USER: [ADMIN_USER, WEB_USER, BANKING_USER],
    GROUP_SHOPPING: [ADMIN_USER, SHOPPING_ADMIN, SHOPPING_USER]
};

export default Roles;