import { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './page-header.css';

function PageHeader(props) {

    const [subtitle, setSubtitle] = useState(props.subtitle);
    const [title, setTitle] = useState(props.title);
    const navigate = props.navigate;
    const icon = props.icon;
    const rightMenu = props.rightMenu;

    useEffect(() => {
        setSubtitle(props.subtitle);
        setTitle(props.title);
        return () => {};
    },  [props.subtitle, props.title]);

    return (
        <Navbar bg='dark' variant='dark' fixed="top" className="page-header" >
            <Container>
                <Navbar.Brand onClick={navigate}>
                    <div className='logo'>
                        <h1 data-text="breachie">breachie</h1>
                    </div>
                    <span className="icon">{icon}</span>
                    <span className="title">{title}</span>
                    <span hidden={!subtitle} className="subtitle" >{subtitle}</span>
                    <span hidden={subtitle} className="subtitle">&nbsp;</span>
                </Navbar.Brand>
                <Nav className='right-menu' style={{outline: 'none', backgroundColor:'transparent', borderColor: 'transparent'}}>
                    {rightMenu}
                </Nav>
            </Container>
        </Navbar>
    );

}


export default PageHeader;