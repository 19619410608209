import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../services/auth.service';
import EventbusService from '../services/eventbus.service';
import { FaHome } from 'react-icons/fa';
import PageHeader from '../common/page-header';
import './home.css';
import homeImage from '../images/home.png';
import getMenuItems from '../app.menu';

function Home() {

    const auth = useAuth();
    function test(message) {
        console.log('home - test', message);
    }
    console.log('register');


    useEffect(() => {
        return () => {

        };
    }, [Date.now(), auth.isAuthenticated()]);

    useEffect(() => {
        EventbusService.on('test', test);
        EventbusService.emit('test', 'hello');
        return () => {
            EventbusService.off(test);
        };
    });


    return (
        <Container fluid="md"  >
            <PageHeader title="Home" icon={<FaHome/>} subtitle="&nbsp;"
                background={homeImage}
                backgroundSize='100%'></PageHeader>
            <Row className="pt-3 " style={{marginTop:'95px'}} >
                {getMenuItems().map(function(menuItem, index) {
                    return (<Col key={index}
                        xs={{span: 12 }}
                        sm={{span: 6 }}
                        md={{span: 4 }}
                        lg={{span: 4 }}
                        xl={{span: 4 }} >
                        <Card bg='dark' as={NavLink} to={menuItem.path}
                            style={{width:'100%',  margin: '0 auto', float:'none' , marginBottom:'30px'}}>
                            <Card.Img variant="top" src={menuItem.image} style={{objectFit: 'cover', maxHeight:'100px'}} />
                            <Card.Body>
                                <Card.Title>{menuItem.icon} {menuItem.title}</Card.Title>
                            </Card.Body>
                        </Card></Col>);
                    })}
            </Row>
        </Container>

    );
}

export default Home;