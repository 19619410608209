import React, { createContext, useEffect, useState } from  'react';

import packageJson from '../../package.json';
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
};


export const AppVersionService = function() {

    function checkVersions() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                console.log(shouldForceRefresh), latestVersion, currentVersion;
            });
    }

    checkVersions();

    return {
        checkVersions
    };
};

const appVersionContext = createContext();
const appVersionService = new AppVersionService();

export const ProvideAppVersion = function({ children }) {

    const [timeLeft, setTimeLeft] = useState(500);
    useEffect(() => {
        // exit early when we reach 0
        //console.log('ProvideAppVersion', timeLeft, global.appVersion);
        if (!timeLeft) {
            return;
        }

        appVersionService.checkVersions();
        // save intervalId to clear the interval when the component re-renders
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000 * 60 * 10);

        // console.log('ProvideAppVersion', timeLeft, global.appVersion);
        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
      }, [timeLeft]);

    return (
        <React.Fragment>
            <appVersionContext.Provider value={appVersionService}>
                {children}
            </appVersionContext.Provider>
        </React.Fragment>
    );
};



export const AppVersion = function() {

    const [currentVersion, setCurrentVersion] = useState(global.appVersion);
    const [latestVersion, setLatestVersion] = useState(global.appVersion);


    return (
        <React.Fragment>
            v{ currentVersion }
        </React.Fragment>
    );
};



