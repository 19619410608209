import React, { useEffect, useState } from  'react';
import { useParams, useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import Moment from 'moment';

/* local */
import * as icons from './../icons';

import { Row, Col, Button, Offcanvas } from 'react-bootstrap';
import { useBankingService } from './banking.service';
import EventbusService from '../services/eventbus.service';

import PageHeader from '../common/page-header';
import EVENTS from '../events';
import BankAppAccountCreateEdit from './side-menu-account-create-edit';
import BankAppAccountClose from './side-menu-account-close';
import BankAppAccountEditTransaction from './side-menu-account-edit-transaction';

import { getHeaderImageByPath } from '../app.menu';

function BankAppAccountTransactions() {

    const params = useParams();
    const navigate = useNavigate();
    const accountId = params.accountId;
    const bankingService = useBankingService();
    const [transactions, setTransactions] = useState([]);
    const [accountName, setAccountName] = useState();
    const [parentAccountId, setParentAccountId] = useState(null);
    const [showAddSideMenu, setShowAddSideMenu]= useState(false);
    const [showEditSideMenu, setShowEditSideMenu]= useState(false);
    const [showCloseSideMenu, setShowCloseSideMenu]= useState(false);
    const [hideAddMenuOption, setHideAddMenuOption]= useState(true);
    const [showEditTransactionMenu, setShowEditTransactionMenu]= useState(false);
    const [canCloseAccount, setCanCloseAccount] = useState(false);
    const [startEditTransaction, setStartEditTransaction] = useState(null);
    const bankingAccountImage = getHeaderImageByPath('/banking/transactions');

    function sortEffectiveOnOrCreatedOn(a, b) {
        if (a.effectiveDate < b.effectiveDate) {
            return 1;
        }
        if (a.effectiveDate > b.effectiveDate) {
            return -1;
        }
        return 0;
    }

    function refreshData() {
        const transactions = bankingService.getAccountTransactions().sort(sortEffectiveOnOrCreatedOn);
        const account = bankingService.getAccount(accountId);

        if (account) {
            setHideAddMenuOption(!(!account.parentAccountId && !bankingService.hasChildAccounts(account.accountId)));
            setParentAccountId(account.parentAccountId);
            setAccountName(account.accountName);
            setCanCloseAccount(account.parentAccountId || !bankingService.hasChildAccounts(account.accountId));
            if (transactions && transactions.length > 0) {
                let runningBalance = account.accountBalance;

                const data = transactions.reduce(function(groupedTransactions, transaction) {
                    const index = groupedTransactions.map(function(groupTransaction) {
                        return groupTransaction.effectiveDate;
                    }).indexOf(transaction.effectiveDate);

                    if (index === -1) {
                        groupedTransactions.push({
                            effectiveDate: transaction.effectiveDate,
                            balance: runningBalance,
                            transactions: [transaction]
                        });
                    } else {
                        groupedTransactions[index].transactions.push(transaction);
                    }

                    runningBalance -= transaction.amount;

                    return groupedTransactions;

                }, []);
                setTransactions(data);
            }
        }
    }

    function loadAccountTransactions() {
        bankingService.loadAccountTransactions(accountId).then(refreshData);
    }

    useEffect(() => {
        loadAccountTransactions();
        EventbusService.on(EVENTS.LOCAL.MYBANK.ACCOUNT.UPDATED, loadAccountTransactions);
        return () => {
            EventbusService.off(loadAccountTransactions);
        };
    }, []);


    function handleClose() {
        setShowEditSideMenu(false);
        setShowCloseSideMenu(false);
        setShowAddSideMenu(false);
        setShowEditTransactionMenu(false);
        loadAccountTransactions();
    }

    function handleCreateAccountClick() {
        setShowEditSideMenu(true);
    }

    function handleCloseAccountClick() {
        setShowCloseSideMenu(true);
    }

    function handleAddAccountClick() {
        setShowAddSideMenu(true);
    }

    function handleAccountClosed() {
        navigate('/banking/accounts');
    }

    function handleEditTransactionClick(transaction) {
        setStartEditTransaction(transaction);
        setShowEditTransactionMenu(true);
    }

    function isSaving() {

    }

    function isSaved(savedState) {
        if(savedState) {
            handleClose();
        }
    }

    function isCancelling() {
        handleClose();
    }


    function editTransaction() {
        return (
            <Offcanvas className='noselect' show={showEditTransactionMenu}
                        onHide={handleClose}
                        backdrop="true"
                        placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Transaction</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                 {
                     <BankAppAccountEditTransaction
                        isSaving={isSaving}
                        isSaved={isSaved}
                        isCancelling={isCancelling}
                        transaction={startEditTransaction}>

                        </BankAppAccountEditTransaction>
                 }
                </Offcanvas.Body>
            </Offcanvas>
          );
    }

    function editMenu() {
        return (
            <Offcanvas className='noselect' show={showEditSideMenu} onHide={handleClose} backdrop="true" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Account</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    { <BankAppAccountCreateEdit
                        mode="edit"
                        isSaving={isSaving}
                        isSaved={isSaved}
                        isCancelling={isCancelling}
                        accountId={accountId}
                        parentAccountId={parentAccountId}/>
                    }
                </Offcanvas.Body>
            </Offcanvas>
          );
    }

    function closeMenu() {
        return (
            <Offcanvas className='noselect' show={showCloseSideMenu} onHide={handleClose} backdrop="true" placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Close Account</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                { <BankAppAccountClose isSaving={isSaving} isSaved={handleAccountClosed} isCancelling={isCancelling} accountId={accountId}  /> }
            </Offcanvas.Body>
            </Offcanvas>
        );
    }


    function navigateBackOne() {
        if (parentAccountId) {
            navigate(`/banking/account/${parentAccountId}`);
        } else if (!bankingService.hasChildAccounts(accountId)) {
            navigate('/banking/accounts');
        } else {
            navigate(`/banking/account/${accountId}`);
        }
    };

    function addMenu() {
        return (
            <Offcanvas className='noselect' show={showAddSideMenu} onHide={handleClose} backdrop="true" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Create Account in {accountName}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    { <BankAppAccountCreateEdit mode="add" isSaving={isSaving} isSaved={isSaved} parentAccountId={accountId}  /> }
                </Offcanvas.Body>
            </Offcanvas>
          );
    }

    const dateLineStyle = {
        lineHeight: '1.5',
        fontSize: '1.0rem',
        fontWeight: '800'
    };

    const transactionLineStyle = {
        lineHeight: '0.4',
        fontSize: '1.0rem',
    };

    return (
        <React.Fragment>
            <PageHeader
                title="Banking"
                icon={<icons.FaPiggyBank/>}
                navigate={navigateBackOne}
                subtitle={accountName}
                background={bankingAccountImage.image}
                backgroundPosition='0 65%'
                rightMenu={<React.Fragment>
                                <Button hidden={hideAddMenuOption} variant="dark" style={{paddingRight:'0px'}} onClick={handleAddAccountClick}>{<icons.BsPlusCircle style={{fontSize: '1.5em'}}/>}</Button>
                                <Button variant="dark" style={{paddingRight:'0px'}} onClick={handleCreateAccountClick}>{<icons.RiEditCircleLine style={{fontSize: '1.5em'}}/>}</Button>
                                <Button variant="dark"
                                    style={{paddingRight:'0px'}}
                                    disabled={!canCloseAccount}
                                    onClick={handleCloseAccountClick}>
                                        {<icons.AiOutlineCloseCircle style={{fontSize: '1.7em'}}/>}</Button>
                            </React.Fragment>}>
            </PageHeader>
            {addMenu()}
            {editMenu()}
            {closeMenu()}
            {editTransaction()}
            <div style={{color:'White', backgroundColor:'#282c34', position: 'sticky', top: '60px' , paddingTop:'50px'}}>
                <Row>
                    <Col xs={{span: 7, offset:0 }} sm={{span: 7, offset:1 }} md={{span: 8, offset:1 }} lg={{span: 5, offset:1 }} xl={{span: 5, offset:1 }}><h4>Date</h4></Col>
                    <Col xs={{span: 5 }} sm={{span: 3 }} md={{span: 3 }} lg={{span: 5 }} xl={{span: 5 }} style={{textAlign: 'right'}}><h4>Balance</h4></Col>
                </Row>
            </div>
            <div style={{paddingTop:'70px', overflow:'hidden' }}>
                {transactions.sort(sortEffectiveOnOrCreatedOn).map((transactionGroup, index) => {
                    return  <React.Fragment key={index} >
                                <Row>
                                    <Col xs={{span: 7, offset:0 }} sm={{span: 7, offset:1 }} md={{span: 8, offset:1 }} lg={{span: 5, offset:1 }} xl={{span: 5, offset:1 }}>
                                        <span style={dateLineStyle}> {Moment(transactionGroup.effectiveDate).format('MMM DD, YYYY')}</span>
                                    </Col>
                                    <Col xs={{span: 5 }} sm={{span: 3 }} md={{span: 3 }} lg={{span: 5 }} xl={{span: 5 }} style={{textAlign: 'right'}}>
                                        <span style={dateLineStyle}>
                                            <NumberFormat
                                                value={(Math.round(transactionGroup.balance * 100)/100).toFixed(2)}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                prefix={'$'}
                                                />
                                        </span>
                                    </Col>
                                </Row>
                                {transactionGroup.transactions.map((tx, i) => {
                                    return (<Row key={i}>
                                                <Col xs={{span: 7, offset:0 }} sm={{span: 7, offset:1 }} md={{span: 8, offset:1 }} lg={{span: 5, offset:1 }} xl={{span: 5, offset:1 }}>
                                                    <span style={transactionLineStyle}>{tx.description || 'No Description'}</span>
                                                </Col>
                                                <Col xs={{span: 5 }} sm={{span: 3 }} md={{span: 3 }} lg={{span: 5 }} xl={{span: 5 }} style={{textAlign: 'right'}}>
                                                    <span style={transactionLineStyle}
                                                        onClick={ () => {handleEditTransactionClick(tx);}}>
                                                    <NumberFormat
                                                        value={(Math.round(tx.amount * 100)/100).toFixed(2)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        prefix={'$'} />
                                                    </span>
                                                </Col>
                                            </Row>);
                                })}
                            </React.Fragment>;
                    })
                }
            </div>
        </React.Fragment>
    );
}


export default BankAppAccountTransactions;