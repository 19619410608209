import React, { useEffect, useState } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Offcanvas, Button } from 'react-bootstrap';
import PageHeader from '../common/page-header';
import { getHeaderImageByPath } from '../app.menu';
import { BsPlusCircle } from 'react-icons/bs';
import ShoppingProductCreateEdit from './side-menu-product-edit';
import API from './../services/api.service';

function ShoppingAppProducts() {

    const params = useParams();
    const [show, setShow]= useState(false);
    const productImage = getHeaderImageByPath('/shopping');
    const navigate = useNavigate();
    const navigateBackOne = () => {
        navigate('/shopping');
    };

    function isSaved(savedState) {
        if(savedState) {
            handleClose();
        }
    }

    function handleClose() {
        setShow(false);
    }

    function handleCreateAccountClick() {
        setShow(true);
    }

    function isSaving(value) {
        console.log('isSaving', value);
    }

    function sideMenu() {
        return (
            <Offcanvas className='noselect' show={show} onHide={handleClose} backdrop="true" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Create Product</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    { <ShoppingProductCreateEdit mode="add" /> }
                </Offcanvas.Body>
            </Offcanvas>
          );
    }

    return (
        <React.Fragment>
            <PageHeader
                title="Products"
                icon={productImage.icon}
                background={productImage.image}
                backgroundPosition='0 65%'
                navigate={navigateBackOne}
                rightMenu={<Button variant="dark" onClick={handleCreateAccountClick}>{<BsPlusCircle style={{fontSize: '1.5em'}}/>}</Button>} >
            </PageHeader>
            {sideMenu()}
            <Row style={{marginTop:'110px'}}>
                <Col
                    xs={{span: 2, offset:0 }}
                    sm={{span: 2, offset:0 }}
                    md={{span: 2, offset:0 }}
                    lg={{span: 2, offset:0 }}
                    xl={{span: 2, offset:0 }}>
                    <h4 className='heading'>Brand</h4>
                </Col>
                <Col
                    xs={{span: 2 }}
                    sm={{span: 2 }}
                    md={{span: 2 }}
                    lg={{span: 2 }}
                    xl={{span: 2 }}
                    ><h4 className='heading'>Product</h4>
                </Col>
                <Col
                    xs={{span: 2 }}
                    sm={{span: 2 }}
                    md={{span: 2 }}
                    lg={{span: 2 }}
                    xl={{span: 2 }}
                    ><h4 className='heading'>Size</h4>
                </Col>

                <Col
                    xs={{span: 2 }}
                    sm={{span: 2 }}
                    md={{span: 2 }}
                    lg={{span: 2 }}
                    xl={{span: 2 }}
                    ><h4 className='heading'>Type</h4>
                </Col>
                <Col
                    xs={{span: 2 }}
                    sm={{span: 2 }}
                    md={{span: 2 }}
                    lg={{span: 2 }}
                    xl={{span: 2 }}
                    ><h4 className='heading'>Market</h4>
                </Col>
            </Row>
            <Row>
                
            </Row>
        </React.Fragment>

    );
}

export default ShoppingAppProducts;