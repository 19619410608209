

function ShoppingApiService({makeRequest, buildRequest}) {

    function postShoppingProduct(payload = {}) {
        return buildRequest().then(function(req) {
            return makeRequest(req.post('/api/shopping/product', payload));
        });
    }

    function getBrands() {
        return buildRequest().then(function(req) {
            return makeRequest(req.get('/api/shopping/brands'));
        });
    }

    function getProducts() {
        return buildRequest().then(function(req) {
            return makeRequest(req.get('/api/shopping/products'));
        });
    }

    function getSizes() {
        return buildRequest().then(function(req) {
            return makeRequest(req.get('/api/shopping/sizes'));
        });
    }

    function getTypes() {
        return buildRequest().then(function(req) {
            return makeRequest(req.get('/api/shopping/types'));
        });
    }

    function getShoppingProducts() {
        return buildRequest().then(function(req) {
            return makeRequest(req.get('/api/shopping/shopping-products'));
        });
    }

    return {
        postShoppingProduct,
        getShoppingProducts,
        getBrands,
        getProducts,
        getSizes,
        getTypes
    };

}


export default ShoppingApiService;