import axios from 'axios';
import { AuthService } from './auth.service';
import BankingApiService from './api.banking.service';
import AuthApiService from './api.auth.service';
import AdminApiService from './api.admin';
import ConfigService from './config.service';
import ShoppingApiService from './api.shopping';
const baseURL = ConfigService.url; //'http://localhost:8002'; //  'https://api.breachie.com';
const axiosApiInstance = axios.create({ baseURL });

axiosApiInstance.interceptors.request.use(request => {
    const isAuthenticated = AuthService.isAuthenticated();
    if (isAuthenticated) {
        const token = AuthService.getRawToken() || null;
        request.headers.Authorization = `bearer ${token}`;
    }
    return request;
}, function(error) {
    return Promise.reject(error);
});

axiosApiInstance.interceptors.response.use(response => {

    console.log('response', response);
    console.log('response.header', response['headers']);
    // const isAuthenticated = AuthService.isAuthenticated();
    // if (isAuthenticated) {
    //     const token = AuthService.getRawToken() || null;
    //     request.headers.Authorization = `bearer ${token}`;
    // }
    return response;
}, function(error) {
    return Promise.reject(error);
});

function defaultCatch(error) {
    if (error.response) {
        throw error.response;
    }
    throw error;
}

function getDataResponse(response) {
    return response.data || response;
}

function buildRequest() {
    return Promise.resolve(axiosApiInstance);
}

function makeRequest(req) {
    return req.then(getDataResponse).catch(defaultCatch);
}

const authApis = new AuthApiService({ makeRequest, buildRequest });
const bankingApis = new BankingApiService({ makeRequest, buildRequest });
const adminApis = new AdminApiService({ makeRequest, buildRequest });
const shoppingApis = new ShoppingApiService({ makeRequest, buildRequest });

function getApiVersion() {
    return buildRequest().then(function(req) {
            return makeRequest(req.get('api/version'));
        });
}

function api() {
    return {
        getApiVersion,
        ...bankingApis,
        ...authApis,
        ...adminApis,
        ...shoppingApis
    };
}
const API = new api();
export default API;