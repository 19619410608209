import { Routes, Route, NavLink , useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';

import PageFourZeroFour from '../common/page-four-zero-four';
import BankAppAccounts from './page-accounts';
import BankAppAccount from './page-account';
import BankAppAccountTransactions from './page-account-transactions';
import BankAppTransactions from './page-transactions';

import PageHeader from '../common/page-header';
import getMenuItems, { getHeaderImageByPath } from '../app.menu';


function BankApp() {

    const bankingImage = getHeaderImageByPath('/banking');
    const navigate = useNavigate();

    function navigateBackOne() {
        navigate('/');
    };

    return (
        <Container fluid="md" >
        <Routes>
            <Route path='/' element={<>
                    <PageHeader title="Banking"
                        icon={bankingImage.icon}
                        navigate={navigateBackOne}
                        subtitle="&nbsp;"></PageHeader>
                         <Row className="pt-3 " style={{marginTop:'95px'}} >
                                {getMenuItems('/banking').map(function(menuItem, index) {
                                        return (<Col key={index}
                                            xs={{span: 12 }}
                                            sm={{span: 6 }}
                                            md={{span: 4 }}
                                            lg={{span: 4 }}
                                            xl={{span: 4 }} >
                                            <Card bg='dark' as={NavLink} to={menuItem.path}
                                                style={{width:'100%',  margin: '0 auto', float:'none' , marginBottom:'30px'}}>
                                                <Card.Img variant="top" src={menuItem.image} style={{objectFit: 'cover', maxHeight:'100px'}} />
                                                <Card.Body>
                                                    <Card.Title>{menuItem.icon} {menuItem.title}</Card.Title>
                                                </Card.Body>
                                            </Card></Col>);
                                    })}

                         </Row>
                      <Row className="pt-3 " style={{marginTop:'95px'}} >
                </Row>
                </>}>
            </Route>
            <Route path='accounts' element={<BankAppAccounts/>}  ></Route>
            <Route path='account/:accountId' element={<BankAppAccount/>}></Route>
            <Route path='account/:accountId/transactions' element={<BankAppAccountTransactions/>}></Route>
            <Route path='transactions/*' element={<BankAppTransactions/>}></Route>
            <Route path='*' element={<PageFourZeroFour/>}></Route>
        </Routes>
    </Container>
    );
}

export default BankApp;

