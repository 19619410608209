import React, { useEffect, useState } from  'react';
import { Navbar, Container, Nav, Modal, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { BiLogInCircle, BiLogOutCircle } from 'react-icons/bi';
//import AppVersion from '../app.reload';
import { useAuth } from '../services/auth.service';
// import EventbusService from '../services/eventbus.service';
import getMenuItems from '../app.menu';

function AppBanner() {
    const sc = useAuth();
    //const eb = useEventbus();

    const [show, setShow] = useState(false);
    const [busy, setBusy] = useState(false);

    const handleLogoutCancelClick = () => setShow(false);
    const handleLogoutConfirmClick = function(e) {
        e.preventDefault();
        setBusy(true);

        sc.logout().then(function() {

        }).finally(function() {
            setBusy(false);
            setShow(false);
        });
    };

    const handleLogoutClick = function(e) {
        setShow(true);
        e.preventDefault();
    };

    useEffect(() => {
//        EventbusService.emit('test', 'hello');
        return () => {

        };
    });

    return (
        <>
            <Navbar bg='dark' variant='dark' fixed='bottom'>
                <Container>
                    <Navbar.Brand as={NavLink} to="/">
                    </Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link as={NavLink} to="/" hidden={!sc.isAuthenticated()} >
                                <span className="d-block d-md-none" ><FaHome /></span>
                                <span className="d-none d-sm-none d-md-block"><FaHome /> Home</span>
                            </Nav.Link>
                            {getMenuItems().map(function(menuItem, index){
                                return (
                                    <Nav.Link key={index} as={NavLink} to={menuItem.path} hidden={!sc.isAuthenticated()} >
                                        <span className="d-block d-md-none" >{menuItem.icon}</span>
                                        <span className="d-none d-sm-none d-md-block">{menuItem.icon} {menuItem.title}</span>
                                    </Nav.Link>
                                );
                            })}
                        </Nav>
                        <Nav>
                            <Nav.Link>
                               { global.appVersion }
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/login" hidden={sc.isAuthenticated()}>
                                <span className="d-block d-md-none" ><BiLogInCircle /></span>
                                <span className="d-none d-sm-none d-md-block"><BiLogInCircle /> Login</span>
                            </Nav.Link>
                            <Nav.Link onClick={handleLogoutClick} hidden={!sc.isAuthenticated()}>
                                <span className="d-block d-md-none" ><BiLogOutCircle /></span>
                                <span className="d-none d-sm-none d-md-block"><BiLogOutCircle /> Logout</span>
                            </Nav.Link>
                        </Nav>
                </Container>
            </Navbar>

            <Modal
                    disabled={true}
                    style={{ paddingTop: '30%' }}
                    show={show}
                    onHide={handleLogoutCancelClick}
                    backdrop="static"
                    keyboard={false}>
                <Modal.Header closeButton={!busy}>
                    <Modal.Title>Logout?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleLogoutConfirmClick} disabled={busy}>Yes</Button>
                    <Button variant="secondary" onClick={handleLogoutCancelClick} disabled={busy}>No</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AppBanner;