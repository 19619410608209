import React, { useEffect, useState } from  'react';
import NumberFormat from 'react-number-format';
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Offcanvas } from 'react-bootstrap';
import { useBankingService } from './banking.service';
import EventbusService from '../services/eventbus.service';
import EVENTS from '../events';
import { BsPlusCircle } from 'react-icons/bs';
import PageHeader from '../common/page-header';
import BankAppAccountCreateEdit from './side-menu-account-create-edit';
import { getHeaderImageByPath } from '../app.menu';

function BankAppAccounts() {

    const navigate = useNavigate();
    const bankingService = useBankingService();
    const [accounts, setAccounts] = useState([]);
    const [show, setShow]= useState(false);
    const bankingAccountImage = getHeaderImageByPath('/banking/accounts');

    // useEffect(()=> {
    //     console.log('getaccount')
    //     setAccounts(bankingService.getAccounts());
    //     return () => {

    //     };
    // }, [bankingService.getAccounts()]);


    useEffect(() => {
        setAccounts(bankingService.getAccounts());
        EventbusService.on(EVENTS.LOCAL.MYBANK.ACCOUNT.UPDATED, setAccounts);
        return () => {
            EventbusService.off(setAccounts);
        };
    }, []);

    function getParentAccounts() {
        return accounts.filter(function(account) {
            return !account.parentAccountId && !account.deletedOn;
        }).sort(function(a, b) {
            if ((a.accountType && a.accountType.order) < (b.accountType && b.accountType.order))  {
                return -1;
            }
            if ((a.accountType && a.accountType.order) > (b.accountType && b.accountType.order))  {
                return 1;
            }
            if (a.accountName < b.accountName) {
                return -1;
            }
            if (a.accountName > b.accountName) {
                return 1;
            }
            return 0;
        });
    }


    function handleClose() {
        setShow(false);
    }

    function handleCreateAccountClick() {
        setShow(true);
    }

    function isSaving(value) {
        console.log('isSaving', value);
    }

    function isSaved(savedState) {
        if(savedState) {
            handleClose();
        }
    }

    function sideMenu() {
        return (
            <Offcanvas className='noselect' show={show} onHide={handleClose} backdrop="true" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Create Account</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    { <BankAppAccountCreateEdit mode="add" isSaving={isSaving} isSaved={isSaved} /> }
                </Offcanvas.Body>
            </Offcanvas>
          );
    }

    function navigateBackOne() {
        navigate('/banking');
    };

    // <DropdownButton 
    //                 align="end" 
    //                 menuVariant="dark" 
    //                 variant="dark" 
    //                 title={<BsPlusCircle style={{fontSize: '1.5em'}}/>} 
    //                 id="dropdown-accounts-menu">
    //     <Dropdown.Item eventKey="1" onClick={handleCreateAccountClick} >Create</Dropdown.Item>
    //   </DropdownButton>

    function getParentGroups() {
        return getParentAccounts().reduce(function(accountGroup, account) {
            const key = account.accountType.code;

            const index = accountGroup.map(function(account){
                return account.key;
            }).indexOf(key);

            if (index === -1) {
                accountGroup.push({ key, accountType: account.accountType, values:[account]});
            } else {
                accountGroup[index].values.push(account);
            }
            return accountGroup;
        }, []);
    }

    function hasChildAccounts(accountId) {
        return bankingService.hasChildAccounts(accountId);
    }

    function getNavigation(accountId) {
        if (!hasChildAccounts(accountId)) {
            return `/banking/account/${accountId}/transactions`;
        }
        return `/banking/account/${accountId}`;
    }

    return (
        <React.Fragment>
            <PageHeader title="Banking"
                icon={bankingAccountImage.icon}
                subtitle="Accounts"
                navigate={navigateBackOne}
                background={bankingAccountImage.image}
                backgroundPosition='0 65%'
                rightMenu={<Button variant="dark"  onClick={handleCreateAccountClick}>{<BsPlusCircle style={{fontSize: '1.5em'}}/>}</Button>}
                 ></PageHeader>
            <Row style={{marginTop:'110px'}}>
                <Col xs={{span: 7, offset:0 }} sm={{span: 7, offset:1 }} md={{span: 8, offset:1 }} lg={{span: 5, offset:1 }} xl={{span: 5, offset:1 }}>
                    <h4>Account Name</h4>
                </Col>
                <Col xs={{span: 5 }} sm={{span: 3 }} md={{span: 3 }} lg={{span: 5 }} xl={{span: 5 }} style={{textAlign: 'right'}}
                    ><h4>Balance</h4>
                </Col>
            </Row>
            {sideMenu()}
            {getParentGroups().map((accountGroup, accountGroupIndex) => {
                return <React.Fragment key={accountGroupIndex} >
                            <Row className="page" >
                                <Col xs={{span: 7, offset:0 }} sm={{span: 7, offset:1 }} md={{span: 8, offset:1 }} lg={{span: 5, offset:1 }} xl={{span: 5, offset:1 }} >
                                    <strong>{accountGroup.accountType.name}</strong>
                                </Col>
                            </Row>
                            {accountGroup.values.map((account, accountIndex) => {
                                return <Row className="page" key={accountIndex} as={NavLink} to={getNavigation(account.accountId)}>
                                            <Col xs={{span: 7, offset:0 }} sm={{span: 7, offset:1 }} md={{span: 8, offset:1 }} lg={{span: 5, offset:1 }} xl={{span: 5, offset:1 }}>
                                                {account.accountName}
                                            </Col>
                                            <Col xs={{span: 5 }} sm={{span: 3 }} md={{span: 3 }} lg={{span: 5 }} xl={{span: 5 }}
                                                style={{textAlign: 'right'}}>
                                                <NumberFormat
                                                    value={account.accountTotalBalance}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'} />
                                            </Col>
                                        </Row>;
                                })}
                        </React.Fragment>;
                    })}
        </React.Fragment>
    );
}

export default BankAppAccounts;

