import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form, Container, Alert, Row, Col, Button} from 'react-bootstrap';
import { useAuth } from '../services/auth.service';

function LoginPage() {

    const navigate = useNavigate();
    const [ showLoginError, setShowLoginError] = useState(false);
    const [ loginError, setLoginError] = useState('');
    const [ busy, setBusy] = useState(false);
    const { register, handleSubmit, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            rememberMe: 'true'
        }
    });
    const loginFont = { fontSize: '1.2rem', fontWeight: '400'};

    const { isDirty, isValid } = formState;
    const auth = useAuth();
    // const config = useConfig();

    const handleAuthClick = function (formData) {

        console.log(formData, auth)
        // setBusy(true);
        setShowLoginError(false);
        return auth.login(formData.userAccount, formData.userPassword, formData.rememberMe)
            .then(function() {
                navigate('/');
                return;
            })
            .catch(function(e) {
                console.log(e)
                if (e.status === 401) {
                    setLoginError('Either account or password was entered incorrectly.');
                } else {
                    setLoginError('An error has occured, bad luck.');
                }
                setShowLoginError(true);
            })
            .finally(function() {
                setBusy(false);
            });
    };

    useEffect(() => {
        console.log('errors', formState);
    }, [formState, loginError]);

    return (
        <Container style={{paddingTop:'50px'}}>
            <Row>
                <Col
                    xs={{span: 10, offset: 1 }}
                    sm={{span:  8, offset: 2 }}
                    md={{span:  8, offset: 2 }}
                    lg={{span:  6, offset: 3 }}
                    xl={{span:  6, offset: 3 }} >
                    <Form autoComplete="off" onSubmit={handleSubmit(handleAuthClick)} >
                        <Form.Group className="mb-3" controlId="userAccount">
                            <Form.Label style={loginFont}>Enter login credentials </Form.Label>

                            <Form.Control type="text" placeholder="enter account" disabled={busy} { ...register('userAccount', { required: true, pattern: /[A-Za-z0-9\.]{1,}@[A-Za-z0-9\.]{1,}/ }) } />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="userPassword">
                            <Form.Control type="password" placeholder="password" disabled={busy} { ...register('userPassword', { required: true, pattern: /[\.A-Za-z0-9]{1,}/ })} />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group className="mb-5" controlId="rememberMe">
                                    <Form.Check style={{ fontSize: '0.9rem', fontWeight: '400'}} type="checkbox" label="Remember Me!"  { ...register('rememberMe')} disabled={busy}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <div style={{display: 'block', float:'right', test: loginFont}} >
                                    <Button className="btn-sm" type="submit" disabled={busy} >Login</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col
                    xs={{span: 10, offset:1 }}
                    sm={{span: 8, offset:2 }}
                    md={{span: 8, offset:2 }}
                    lg={{span: 6, offset:3 }}
                    xl={{span: 6, offset:3 }} >
                <Alert show={showLoginError} onClose={() => setShowLoginError(false)} variant="danger" dismissible>
                    <Alert.Heading>Authentication Failed!</Alert.Heading>{loginError}
                </Alert>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginPage;