import Select from 'react-select';
import uuid from 'react-uuid';
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useBankingService } from './banking.service';

function BankAppAccountCreateEdit(props) {

    const isSaving = props.isSaving;
    const isSaved = props.isSaved;
    const accountId = props.accountId || uuid();
    const parentAccountId = props.parentAccountId || null;
    const [busy, setBusy] = useState(false);
    const [validated, setValidated] = useState(false);
    const bankingService = useBankingService();
    const isCreateMode = props.mode === 'add';
    const buttonText = isCreateMode ? 'Create': 'Update';
    const newAccount = bankingService.getAccount(accountId) || { accountId };
    const accountTypeDefault = newAccount.accountType ? { value: newAccount.accountType.code, label: newAccount.accountType.name } : null;


    if (parentAccountId) {
        newAccount.parentAccountId = parentAccountId;
    }

    function sortAccounts(a, b) {
        if (a.order > b.order) {
            return 1;
        }
        if (a.order < b.order) {
            return -1;
        }
        return 0;
    }

    const accountTypes = bankingService.getAccountTypes().sort(sortAccounts).map(function(accountType) {
        return {
            value: accountType.code,
            label: accountType.name
        };
    });

    function accountTypeChanged(selectedAccountType) {
        newAccount.accountType = bankingService.getAccountTypes().find(function(accountType) {
            return accountType.code === selectedAccountType.value;
        });
    }

    function onAccountNameChange(event) {
        newAccount.accountName = event.target.value;
    }

    function onSubmit(event) {

        console.log('onSubmit', parentAccountId, newAccount)
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        console.log(form.checkValidity() )
        if (form.checkValidity() === false || (!parentAccountId && !newAccount.accountType)) {
            console.log('hhsdhasdh', form.checkValidity(), parentAccountId,newAccount)
            return;
        }


        isSaving(true);
        setValidated(true);
        setBusy(true);

        console.log(newAccount)

        return bankingService.postAccount(newAccount)
            .then(function() {
                isSaved(true);
            })
            .catch(function(error) {
                console.log(error);
                isSaving(false);
                setBusy(false);
            });
    }

    const customStyles = {
        control: function(provided, state) {
            return {
                ...provided,
                borderRadius: '5px',
                color: state.isFocused ? '#212529' : provided.color,
                backgroundColor: '#fff',
                borderColor:  state.isFocused ? '#86b7fe' : provided.borderColor,
                outline: state.isFocused ? '0' : null,
                boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : null
            };
        }
    };

    function getAccountType() {
        if (!parentAccountId){
            return (

                <Form.Group className="mb-3" controlId="formAccountType" hidden={!!parentAccountId}>
                <Form.Label>Account Type</Form.Label>
                <Select id="formAccountType" disabled={busy}
                        name="formAccountType"
                        styles={customStyles}
                        options={accountTypes}
                        defaultValue={accountTypeDefault}
                        onChange={accountTypeChanged}
                        required/>
                <Form.Control.Feedback type="invalid">
                    Select used Account Type!
                </Form.Control.Feedback>
            </Form.Group>
            );
        };
        return (<></>);
    }

    return (
        <div>
            <Form autoComplete="off" noValidate validated={validated} onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="formAccountName">
                    <Form.Label>Account Name</Form.Label>
                    <Form.Control type="text" onChange={onAccountNameChange}
                                    defaultValue={newAccount.accountName}
                                    required disabled={busy}
                                    placeholder="Enter account name" />
                    <Form.Control.Feedback type="invalid">
                        Invalid or used Account Name!
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">Don't use exiting account name.</Form.Text>
                </Form.Group>

                {getAccountType()}
      
                <Button variant="primary" type="submit" disabled={busy} >
                    {buttonText}
                </Button>
            </Form>
        </div>
    );
}

export default BankAppAccountCreateEdit;