import React, { useEffect, useState } from  'react';
import NumberFormat from 'react-number-format';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Offcanvas } from 'react-bootstrap';
import { useBankingService } from './banking.service';
import EventbusService from '../services/eventbus.service';
import BankAppAccountCreateEdit from './side-menu-account-create-edit';
import EVENTS from '../events';
import { FaPiggyBank } from 'react-icons/fa';
import { BsPlusCircle } from 'react-icons/bs';
import PageHeader from '../common/page-header';
import { getHeaderImageByPath } from '../app.menu';

function BankAppAccount() {

    const params = useParams();
    const navigate = useNavigate();
    const accountId = params.accountId;
    const bankingService = useBankingService();
    const bankingAccountImage = getHeaderImageByPath('/banking/accounts');

    const [accounts, setAccounts] = useState([]);
    const [accountName, setAccountName] = useState();
    const [show, setShow]= useState(false);

    function setSubtitle(data) {
        const parentAccount = data.find(function(account) {
            return account.accountId === accountId;
        });
        setAccountName(parentAccount && parentAccount.accountName);
    }

    function processUpdate(data) {
        setAccounts(data);
        setSubtitle(data);
    }

    useEffect(() => {
        processUpdate(bankingService.getAccounts());
        EventbusService.on(EVENTS.LOCAL.MYBANK.ACCOUNT.UPDATED, processUpdate);
        return () => {
            EventbusService.off(processUpdate);
        };
    }, []);

    function handleClose() {
        setShow(false);
    }

    function handleCreateAccountClick() {
        setShow(true);
    }

    function isSaving(value) {
        console.log('isSaving', value);
    }

    function isSaved(savedState) {
        if(savedState) {
            handleClose();
        }
    }

    function sideMenu() {
        return (
            <Offcanvas className='noselect' show={show} onHide={handleClose} backdrop="true" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Create Account in {accountName}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    { <BankAppAccountCreateEdit mode="add" isSaving={isSaving} isSaved={isSaved} parentAccountId={accountId}  /> }
                </Offcanvas.Body>
            </Offcanvas>
          );
    }

    function getParentAccounts() {
        return accounts.filter(function(account) {
            return (account.accountId === params.accountId || account.parentAccountId === params.accountId) && !account.deletedOn;
        }).sort(function(a, b) {
            if (a.accountId === params.accountId || b.accountId === params.accountId) {
                return 1;
            }
            if (a.accountName < b.accountName) {
                return -1;
            }
            if (a.accountName > b.accountName) {
                return 1;
            }
            return 0;
        });
    }

    const navigateBackOne = () => {
        navigate('/banking/accounts');
    };

    return (
        <React.Fragment>
            <PageHeader
                title="Banking"
                navigate={navigateBackOne}
                subtitle={accountName}
                icon={<FaPiggyBank/>}
                background={bankingAccountImage.image}
                backgroundPosition='0 65%'
                rightMenu={<Button variant="dark" onClick={handleCreateAccountClick}>{<BsPlusCircle style={{fontSize: '1.5em'}}/>}</Button>} >
            </PageHeader>
            <Row style={{marginTop:'110px'}}>
                <Col xs={{span: 7, offset:0 }} sm={{span: 7, offset:1 }} md={{span: 8, offset:1 }} lg={{span: 5, offset:1 }} xl={{span: 5, offset:1 }}>
                    <h4>Account Name</h4>
                </Col>
                <Col xs={{span: 5 }} sm={{span: 3 }} md={{span: 3 }} lg={{span: 5 }} xl={{span: 5 }} style={{textAlign: 'right'}}
                    ><h4>Balance</h4>
                </Col>
            </Row>
            {sideMenu()}
            {getParentAccounts().map(function(account){
                return (<Row className="page" key={account.accountId} as={NavLink} to={'/banking/account/' + account.accountId + '/transactions'}>
                            <Col xs={{span: 7, offset:0 }} sm={{span: 7, offset:1 }} md={{span: 8, offset:1 }} lg={{span: 5, offset:1 }} xl={{span: 5, offset:1 }}>
                                {account.accountName}
                            </Col>
                            <Col xs={{span: 5 }} sm={{span: 3 }} md={{span: 3 }} lg={{span: 5 }} xl={{span: 5 }} style={{textAlign: 'right'}}>
                                <NumberFormat
                                    value={(Math.round(account.accountBalance * 100)/100).toFixed(2)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={'$'} />
                            </Col>
                        </Row>);
            })}

        </React.Fragment>
    );
}


export default BankAppAccount;
