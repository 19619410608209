import React, { createContext, useContext } from  'react';
import { Navigate, useNavigate } from 'react-router-dom';
import API from './api.service';
import EventbusService from './eventbus.service';
import EVENTS from '../events';
import jwt_decode from 'jwt-decode';

const TOKEN_ID = 'session_token';
const authContext = createContext();

export const ProvideAuth = function({ children }) {
    const auth = new LoginService();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
};

export const useAuth = function() {
    return useContext(authContext);
};

export const PrivateRoute = function ({ groupRoles, children }) {
    const auth = useAuth();
    if (!groupRoles || groupRoles.length === 0) {
        return auth && auth.isAuthenticated() ?  children : <Navigate to="/login" />;
    }
    if (auth && auth.isAuthenticated()) {

        if (!auth.isInAnyRole(groupRoles)) {
            return <Navigate to="/" />;
        }
        return children;
    }
    return  <Navigate to="/login" />;
};

export const AuthService = function() {
    let sessionToken;

    function getRawToken() {
        const useToken = sessionToken || localStorage.getItem(TOKEN_ID);
        return useToken;
    }

    function getToken() {
        const token = getRawToken();
        try {
            return jwt_decode(token);
        } catch(params) {
            return null;
        };
    }

    function isInRole(role) {
        const userToken = getToken();
        if (!role && !userToken) {
            return false;
        }
        return userToken.roles && Array.isArray(userToken.roles) && userToken.roles.includes(role);
    }

    function isInAnyRole(roles) {
        if (roles && Array.isArray(roles)) {
            return roles.reduce(function(authorized, role) {
                if (!authorized) {
                    return isInRole(role);
                }
                return authorized;
            }, false);
        }
        return false;
    }

    function setSession(token, rememberMe) {
        sessionToken = token;
        if (!sessionToken) {
            localStorage.setItem(TOKEN_ID, sessionToken);
            EventbusService.emit(EVENTS.LOGGED_OUT);
        } else {
            EventbusService.emit(EVENTS.LOGGED_IN);
            if(rememberMe) {
                localStorage.setItem(TOKEN_ID, sessionToken);
            }
            if (rememberMe == false) {
                localStorage.setItem(TOKEN_ID, null);
            }
        }
    }

    function getSession() {
        return sessionToken;
    }

    function isAuthenticated() {
        const userToken = getToken();
        const isOk = userToken && userToken.exp && userToken.exp * 1000 > Date.now() ? true : false;
        if (!isOk) {
            setSession(null);
        }
        return isOk;
    }

    let interval = null;
    async function checkAuthStatus() {
        clearInterval(interval);

        if (isAuthenticated()) {
            const userToken = getToken();
            const allowedDruation = (userToken.exp - userToken.iat) * 1000;
            const milliseconds = userToken && userToken.exp && userToken.exp * 1000 - Date.now();
            console.log(`alowedDruation: ${allowedDruation/100}, milliseconds: ${milliseconds / 1000}, ${allowedDruation/2} seconds: ${milliseconds/1000}, minutes: ${milliseconds/1000/60}`);
            if (milliseconds / 1000 < (allowedDruation / 2)) {
                console.log(`calling: ${allowedDruation}, milliseconds: ${milliseconds}, seconds: ${milliseconds/1000}, minutes: ${milliseconds/1000/60}`);

                await API.getRefreshToken().then(function(result) {
                    console.log(result);

                    if (result.token) {
                        setSession(result.token, true);
                        interval = setInterval(checkAuthStatus, 100000);
                    }
                }).catch(function(ex) {
                    console.error(ex);
                    interval = setInterval(checkAuthStatus, allowedDruation / 1000);
                }).finally(function() {
                });
            }
            else {
                interval = setInterval(checkAuthStatus, allowedDruation / 1000);
            }

        } else {
            interval = setInterval(checkAuthStatus, 1000);
        }
    }
    getToken();
    checkAuthStatus();

    return {
        setSession,
        getSession,
        getRawToken,
        isAuthenticated,
        isInAnyRole,
        isInRole
    };

}();

function LoginService() {

    const navigate = useNavigate();
    function logout() {
        return new Promise(function(resolve) {
            AuthService.setSession(null);
            setTimeout(resolve, 50);
        }).then(function() {
            navigate('/', { replace: true });
            return;
        });
    }

    function login(username, password, rememberMe) {
        return API.postAuth(username, password)
            .then(function(response) {
                if(response && response.token) {
                    AuthService.setSession(response.token, rememberMe);
                    return true;
                }
                return false;
            });
    }

    return {
        login,
        logout,
        isAuthenticated: AuthService.isAuthenticated,
        isInAnyRole: AuthService.isInAnyRole,
        isInRole: AuthService.isInRole
    };
}

export default LoginService;
