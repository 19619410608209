import React from 'react';
import { Routes, Route, NavLink, useNavigate } from 'react-router-dom';
import PageHeader from '../common/page-header';
import { Container, Row, Col, Card } from 'react-bootstrap';
import BankAppTransactionsManual from './page-transactions-manual';
import getMenuItems, { getHeaderImageByPath } from '../app.menu';

const path = '/banking/transactions';
function BankAppTransactions() {

    const navigate = useNavigate();

    const bankingImage = getHeaderImageByPath(path);

    function navigateBackOne() {
        navigate('/banking');
    };

    return (
        <Container fluid="md" >
            <Routes>
                <Route path='/manual' element={<BankAppTransactionsManual/>}></Route>
                <Route path='/' element={<React.Fragment>
                        <PageHeader title="Transactions"
                            icon={bankingImage.icon}
                            backgroundSize='100%'
                            backgroundPosition='0% 75%'
                            navigate={navigateBackOne}
                            background={bankingImage.image}
                            subtitle="&nbsp;"></PageHeader>
                        <Row className="pt-3 " style={{marginTop:'95px'}} >
                            {getMenuItems(path).map(function(menuItem, index) {
                                return (<Col key={index}
                                    xs={{span: 12 }}
                                    sm={{span: 6 }}
                                    md={{span: 4 }}
                                    lg={{span: 4 }}
                                    xl={{span: 4 }} >
                                    <Card bg='dark' as={NavLink} to={menuItem.path}
                                        style={{width:'100%',  margin: '0 auto', float:'none' , marginBottom:'30px'}}>
                                        <Card.Img variant="top" src={menuItem.image} style={{objectFit: 'cover', maxHeight:'100px'}} />
                                        <Card.Body>
                                            <Card.Title>{menuItem.icon} {menuItem.title}</Card.Title>
                                        </Card.Body>
                                    </Card></Col>);
                            })}
                        </Row>
                    </React.Fragment>} >
                </Route>
            </Routes>
        </Container>
    );

}

export default BankAppTransactions;