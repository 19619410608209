import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import ConfigService from './services/config.service';
import WebsocketService from './services/websocket.service';
import EVENTS from './events';
import EventbusService from './services/eventbus.service';


WebsocketService.init(ConfigService.url);
EventbusService.on(EVENTS.LOGGED_IN, function() { console.log('index - Event'); });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Router><App/></Router>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
