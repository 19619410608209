import BROADCAST_LISTENER from './services/websocket.events';
const LOCAL_EVENT_NAMESPACE = 'local';
const MYBANK_EVENT_NAMESPACE = `${LOCAL_EVENT_NAMESPACE}.mybank`;
const BACKEND_EVENT_NAMESPACE = 'Backend';
const BACKEND_MYBANK_EVENT_NAMESPACE = `${BACKEND_EVENT_NAMESPACE}.Mybank`;

function getToLocalEvent(event) {
    return event.replace('Backend', LOCAL_EVENT_NAMESPACE).toLowerCase();
}

const EVENTS = {
    BROADCAST_LISTENER,
    LOGGED_IN: 'logged_in',
    LOGGED_OUT: 'logged_out',
    LOGGED_IN_AUTHORIZED: 'logged_in_authorized',
    HTTP_UNAUTHORIZED: 'http_unauthorized',
    ACCOUNTS_UPDATED: 'accounts_updated',
    ACCOUNTS_INITALIZED: 'accounts_initalized',
    CATEGORIES_UPDATED: 'categories_updated',
    LOCAL:{
        MYBANK: {
            ACCOUNT: {
                INIALIZED: `${MYBANK_EVENT_NAMESPACE}.account.initalized`,
                UPDATED: `${MYBANK_EVENT_NAMESPACE}.account.updated`,
                DELETED: `${MYBANK_EVENT_NAMESPACE}.account.deleted`
            },
            BUDGET: {
                INIALIZED: `${MYBANK_EVENT_NAMESPACE}.budget.initalized`,
                UPDATED: `${MYBANK_EVENT_NAMESPACE}.budget.updated`,
                DELETED: `${MYBANK_EVENT_NAMESPACE}.budget.ueleted`
            },
            CATEGORY: {
                UPDATED: `${MYBANK_EVENT_NAMESPACE}.category.updated`,
                LOADED: `${MYBANK_EVENT_NAMESPACE}.category.loaded`
            }
        }
    },
    BROADCAST_EVENTS: {
        EVENT_NAMESPACE: BACKEND_MYBANK_EVENT_NAMESPACE,
        MYBANK: {
            ACCOUNT: {
                INIALIZED: `${BACKEND_MYBANK_EVENT_NAMESPACE}.Account.Initalized`,
                UPDATED: `${BACKEND_MYBANK_EVENT_NAMESPACE}.Account.Updated`,
                DELETED: `${BACKEND_MYBANK_EVENT_NAMESPACE}.Account.Deleted`
            },
            BUDGET: {
                INIALIZED: `${BACKEND_MYBANK_EVENT_NAMESPACE}.Budget.Initalized`,
                UPDATED: `${BACKEND_MYBANK_EVENT_NAMESPACE}.Budget.Updated`,
                DELETED: `${BACKEND_MYBANK_EVENT_NAMESPACE}.Budget.Deleted`
            },
            CATEGORY: {
                UPDATED: `${BACKEND_MYBANK_EVENT_NAMESPACE}.Category.Updated`
            }
        }
    },
    getToLocalEvent
};

export default EVENTS;