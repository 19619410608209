import React, { useState, useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import EventbusService from '../services/eventbus.service';
import { useAuth } from '../services/auth.service';
import Moment from 'react-moment';

export const AppAlerts = function() {
    const [alertCollection, setAlertCollection] = useState([]);
    const as = useAuth();

    useEffect(() => {
        const timer = setInterval(() => {
            var alert = alertCollection.find(function(a) {
                return a.expires < Date.now();
            });

            if (alert && !alert.show) {
                closeAlert(alert);
            }
            if (alert && alert.show) {
                alert.show = false;
            }

        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [alertCollection]);


    function addAlert() {
        if (as.isAuthenticated()) {
            // const copy = alertCollection;
            // copy.push({ message: 'test', id: uuidv4(), expires: Date.now() + 10000, createdOn: Date.now(), show:true });
            // setAlertCollection(copy);
        }
    }

    useEffect(() => {
        EventbusService.on('test', addAlert);
        return () => {
            EventbusService.off(addAlert);
        };
    });

    function getAlerts() {
        return alertCollection.sort(function(a,b) {
            if (a.createdOn < b.createdOn) {
                return 1;
            }
            if (a.createdOn > b.createdOn) {
                return -1;
            }
            return 0;
        });
    }

    function closeAlert(a) {
        const copy = alertCollection.filter(function(alert) {
            return alert.id !== a.id;
        });
        setAlertCollection(copy);
    }

    return (
            <ToastContainer position="bottom-end" className="p-5" style={ {'marginBottom': '60px'} }>
                {
                    getAlerts().map(function(alert, index) {
                        return <Toast key={index} show={alert.show || true} onClose={closeAlert.bind(null, alert)} animation={true} >
                                    <Toast.Header>
                                        <img
                                            src="holder.js/20x20?text=%20"
                                            className="rounded me-2"
                                            alt="" />
                                            <strong className="me-auto">{alert.message}</strong>
                                            <small> <Moment date={alert.createdOn} fromNow ago/> ago</small>
                                    </Toast.Header>
                                    <Toast.Body>
                                        Woohoo, you're reading this text in a Toast!
                                    </Toast.Body>
                                </Toast>;
                    })
                }
            </ToastContainer>

    );
};

