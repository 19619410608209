
import { useLocation } from 'react-router';

function PageFourZeroFour() {
    let location = useLocation();

    return (
      <div>
        <h3>
          404 <code>{location.pathname}</code>
        </h3>
      </div>
    );
}

export default PageFourZeroFour;