import { Routes, Route, NavLink, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ShoppingAppProducts from './page-products';
import PageFourZeroFour from '../common/page-four-zero-four';
import PageHeader from '../common/page-header';
import getMenuItems, { getHeaderImageByPath } from '../app.menu';


function ShoppingApp() {

    const bankingImage = getHeaderImageByPath('/shopping');
    const navigate = useNavigate();

    function navigateBackOne() {
        navigate('/');
    };

    return (
        <Container fluid="md">
              <Routes>
            <Route path='/' element={<>
                    <PageHeader title="Shopping"
                        icon={bankingImage.icon}
                        navigate={navigateBackOne}
                        subtitle="&nbsp;"></PageHeader>
                         <Row className="pt-3 " style={{marginTop:'95px'}} >
                                {getMenuItems('/shopping').map(function(menuItem, index) {
                                        return (<Col key={index}
                                            xs={{span: 12 }}
                                            sm={{span: 6 }}
                                            md={{span: 4 }}
                                            lg={{span: 4 }}
                                            xl={{span: 4 }} >
                                            <Card bg='dark' as={NavLink} to={menuItem.path}
                                                style={{width:'100%',  margin: '0 auto', float:'none' , marginBottom:'30px'}}>
                                                <Card.Img variant="top" src={menuItem.image} style={{objectFit: 'cover', maxHeight:'100px'}} />
                                                <Card.Body>
                                                    <Card.Title>{menuItem.icon} {menuItem.title}</Card.Title>
                                                </Card.Body>
                                            </Card></Col>);
                                    })}

                         </Row>
                      <Row className="pt-3 " style={{marginTop:'95px'}} >
                </Row>
                </>}>
            </Route>
            <Route path='products' element={<ShoppingAppProducts></ShoppingAppProducts>} ></Route>

            <Route path='*' element={<PageFourZeroFour/>}></Route>
        </Routes>
        </Container>
    );
}

export default ShoppingApp;